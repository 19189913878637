import styled from 'styled-components'

const generateTypographyStyles = (theme, typeStyle) => {
  const breakpoints = ['lg', 'md', 'sm']
  let styles = ''
  for (let i = 0; i < breakpoints.length; i++) {
    const breakpoint = breakpoints[i]
    const innerStyles = `
    font-size: ${theme.webMktTypography.typography[typeStyle][breakpoint].fontSize};
      font-weight: ${theme.webMktTypography.typography[typeStyle][breakpoint].fontWeight};
      letter-spacing: ${theme.webMktTypography.typography[typeStyle][breakpoint].letterSpacing};
      line-height: ${theme.webMktTypography.typography[typeStyle][breakpoint].lineHeight};
    `
    if (breakpoint !== 'lg') {
      styles += `
    ${theme.breakpoints.down(breakpoint)} {
      ${innerStyles}
    }
    `
    } else {
      styles += innerStyles
    }
  }
  return `
  font-family: 'Inter', sans-serif;
  ${styles}`
}

// Micro
export const MicroAlt = styled.p`
  text-transform: uppercase;

  ${(p) => {
    return generateTypographyStyles(p.theme, 'microALT')
  }}
`

// Captions
export const Caption = styled.p`
  && {
    ${(p) => {
      const generateStyles = (breakpoint) => {
        const styles = `
      font-size: ${p.theme.webMktTypography.typography.caption[breakpoint].fontSize};
      font-weight: ${p.theme.webMktTypography.typography.caption[breakpoint].fontWeight};
      letter-spacing: ${p.theme.webMktTypography.typography.caption[breakpoint].letterSpacing};
      line-height: ${p.theme.webMktTypography.typography.caption[breakpoint].lineHeight};  
    `
        return `
      ${styles}
      p {
        ${styles}
      }
    `
      }
      return `
    ${generateStyles('lg')}
    ${p.theme.breakpoints.down('md')} {
      ${generateStyles('md')}
    }
    ${p.theme.breakpoints.down('sm')} {
      ${generateStyles('sm')}
    }
    `
    }}
  }
`

export const CaptionAlt = styled.p`
  text-transform: uppercase;

  ${(p) => {
    return generateTypographyStyles(p.theme, 'captionAlt')
  }}
`

// Labels
export const Label = styled.p`
  && {
    ${(p) => {
      return generateTypographyStyles(p.theme, 'label')
    }}
  }
  color: rgb(0, 0, 0);
`

export const LabelAlt = styled.p`
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  ${(p) => {
    return generateTypographyStyles(p.theme, 'labelAlt')
  }}
`

// Lead
export const Lead = styled.p`
  color: rgb(0, 0, 0);
  ${(p) => {
    return generateTypographyStyles(p.theme, 'lead')
  }}
`

export const LeadXS = styled.p`
  color: rgb(0, 0, 0);

  ${(p) => {
    return ` 
      ${generateTypographyStyles(p.theme, 'leadXS')}
      p,
      ul,
      li  {
        color: rgb(0, 0, 0);
        ${generateTypographyStyles(p.theme, 'leadXS')}
      }
    `
  }}
`

// Body
export const Body = styled.p`
  color: rgb(0, 0, 0);

  ${(p) => {
    return ` 
      ${generateTypographyStyles(p.theme, 'body')}
      p,
      ul,
      li  {
        color: rgb(0, 0, 0);
        ${generateTypographyStyles(p.theme, 'body')}
      }
    `
  }}
`

export const BodyXS = styled.p`
  margin: 0;
  color: rgb(0, 0, 0);

  ${(p) => {
    return ` 
      ${generateTypographyStyles(p.theme, 'bodyXS')}
      p,
      ul,
      li  {
        color: rgb(0, 0, 0);
        ${generateTypographyStyles(p.theme, 'bodyXS')}
      }
    `
  }}
`

export const BodyXSWrapper = styled.div`
  margin: 0;

  color: rgb(0, 0, 0);

  ${(p) => {
    return `
    ${generateTypographyStyles(p.theme, 'bodyXS')}
    p,li {
      margin: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      font-family: inherit;
    }
    `
  }}
`

// Headers
export const Header1 = styled.h1`
  color: rgb(0, 0, 0);

  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'heading1')}
    `
  }}
`
export const Header2 = styled.h2`
  margin: 0;

  color: rgb(0, 0, 0);

  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'heading2')}
    `
  }}
`
export const Header3 = styled.h3`
  margin: 0;

  color: rgb(0, 0, 0);

  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'heading3')}
    `
  }}
`
export const Header4 = styled.h4`
  margin: 0;

  color: rgb(0, 0, 0);

  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'heading4')}
    `
  }}
`
export const Header5 = styled.h5`
  margin: 0;

  color: rgb(0, 0, 0);

  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'heading5')}
    `
  }}
`
export const Header6 = styled.h6`
  margin: 0;

  color: rgb(0, 0, 0);

  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'heading6')}
    `
  }}
`

// Display
export const Display = styled.p`
  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'display')}
    `
  }}
`

export const DisplayXL = styled.p`
  ${(p) => {
    return `
      ${generateTypographyStyles(p.theme, 'displayXL')}
    `
  }}
`

// Fine Print
export const FinePrint = styled(BodyXS)`
  color: rgba(0, 0, 0, 0.3);

  p,
  .ContentBlock__Fineprint,
  .ContentBlock__Fineprint > p {
    margin: 0;
    ${(p) => {
      return `
      ${generateTypographyStyles(p.theme, 'bodyXS')}
    `
    }}

    color: rgba(0, 0, 0, 0.3);
  }
`

// Libraries
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

type ImgProps = {
  lazyLoad?: boolean
  src: string
  alt?: string
  width?: number
  height?: number
  threshold?: number
  className?: string
  id?: string
  loading?: "eager" | "lazy" | undefined
}

const WrapperImage = ({
  lazyLoad=false,
  src,
  alt,
  width,
  height,
  threshold,
  className,
  id,
  loading,
}: ImgProps) => {
  return lazyLoad ? (
    <LazyLoadImage 
      data-cy="WrapperImage"
      src={src}
      alt={alt}
      width={width}
      height={height}
      threshold={threshold}
      id={id}
      className={className}
      loading={loading}
    />
  ) : (
    <img
      data-cy="WrapperImage"
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      id={id}
      loading={loading}
    />
  )
}

export default WrapperImage

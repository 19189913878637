// Libraries
import React, { useState, useRef, useEffect } from 'react'

// Components
import Search from '../search'
import { HeaderSearch, MegamenuContainer, HeaderContainer } from './header'
import headerStaticQuery from './headerStaticQuery'
import Button from '../button'
import Typography from '../typography'
import formatInlineCode from '../../../utils/shortTextFormatting'

// Images
import full_logo from '../../../../static/steam-img/UiPath-full-logo.svg'

// Constants
import { ModuleNames } from '../../../constants/componentNames.constants'
import {
  LocaleCodes,
  LanguageSelect,
} from '../../../constants/localization.constants'

// Helpers
import { isExternalURL, createLinkBasedOnType } from '../../../utils/link.utils'
import {
  getMicrocopyWithoutNumber,
  matchLocaleToMicrocopy,
} from '../../../utils/microcopy.utils'
import {
  localizedHomepageLink,
  returnMatchingLocaleInfo,
  returnSelectedLocaleData,
} from '../../../utils/functions.utils'

type MenuGroupProps = {
  newGroupName: string
  pages: any
  parentLink?: any
  groupStyle?: string
  highlightedLinks?: any
  greyColumns?: string
  greyColumnAlignment?: string
}

type HeaderProps = {
  locale: string
}

export interface NavigationResult {
  entryLocale: any
  location: any
  menuGroups: any
  name: any
  node_locale: any
  primaryCta: any
  topHatLinks: any
}

const Header = (props: HeaderProps) => {
  const [showSearch, setShowSearch] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [keywords, setKeywords] = useState('')
  const [timer, setTimer] = useState(null)
  const [contentfulNavigation, setContentfulNavigation] =
    useState<NavigationResult | null>(null)
  const leftMostMenu = useRef(null)
  const sixColumns = useRef(null)

  let bodyElement: any

  let dynamicData = headerStaticQuery.allContentfulNavMenu()

  const navEntryLocale = props.locale ? props.locale : LocaleCodes.ENGLISH.code

  const renderAppropriateNav = () => {
    if (
      dynamicData &&
      dynamicData.allContentfulNavMenu &&
      Array.isArray(dynamicData.allContentfulNavMenu.nodes)
    ) {
      if (props.locale) {
        const navToRender = returnSelectedLocaleData(
          dynamicData.allContentfulNavMenu.nodes,
          navEntryLocale
        )
        if (
          navToRender &&
          Array.isArray(navToRender) &&
          navToRender.length !== 0 &&
          navToRender[0]
        ) {
          if (typeof window !== `undefined`) {
            setContentfulNavigation(navToRender[0])
          } else {
            return navToRender[0]
          }
        } else {
          // If there's no localized nav, show the English one
          const englishNav = returnSelectedLocaleData(
            dynamicData.allContentfulNavMenu.nodes,
            LocaleCodes.ENGLISH.code
          )
          if (
            englishNav &&
            Array.isArray(englishNav) &&
            englishNav.length !== 0 &&
            englishNav[0]
          ) {
            if (typeof window !== `undefined`) {
              setContentfulNavigation(englishNav[0])
            } else {
              return englishNav[0]
            }
          }
        }
      }
    }
  }

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen)
    if (menuOpen) {
      document.body.classList.remove('menu-open')
    } else {
      document.body.classList.add('menu-open')
    }
  }

  const handleAccordionOpen = (event: any) => {
    let current = event.currentTarget.classList.contains('is-active')
    if (current) {
      event.currentTarget.classList.remove('is-active')
    } else {
      let menuItems = document.querySelectorAll('.js-Menu-item')
      for (let item of menuItems) {
        if (item !== event.currentTarget) item.classList.remove('is-active')
      }
      event.currentTarget.classList.add('is-active')
    }
  }

  const handleSearchClick = (e: any) => {
    e.preventDefault()
    if (!showSearch) {
      let searchInput = document.querySelector('#gcs__input')
      if (searchInput) {
        searchInput.focus()
        if (bodyElement) {
          bodyElement.classList.add('overflow-hidden')
        }
      }
    } else {
      if (bodyElement) {
        bodyElement.classList.remove('overflow-hidden')
      }
    }
    setShowSearch(!showSearch)
  }

  const setSearchLanguage = () => {
    const urlPath = window.location.pathname.split('/')
    let searchListingURL = `${window.location.origin}/search?q=`

    if (urlPath && urlPath.length > 1) {
      switch (urlPath[1]) {
        case 'de':
          searchListingURL = `${window.location.origin}/de/search?q=`
          break
        case 'fr':
          searchListingURL = `${window.location.origin}/fr/search?q=`
          break
        case 'pt':
          searchListingURL = `${window.location.origin}/pt/search?q=`
          break
        case 'es':
          searchListingURL = `${window.location.origin}/es/search?q=`
          break
        case 'ja':
          searchListingURL = `${window.location.origin}/ja/search?q=`
          break
        case 'ko':
          searchListingURL = `${window.location.origin}/ko/search?q=`
          break
        default:
          searchListingURL = `${window.location.origin}/search?q=`
      }
    } else {
      searchListingURL = `${window.location.origin}/search?q=`
    }

    return searchListingURL
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    const searchListingURL = setSearchLanguage()
    if (encodeURIComponent(keywords.trim()).length >= 3) {
      var searchInputEncoded = encodeURIComponent(keywords)
        .split('%20')
        .join('+')
      window.location.href = searchListingURL + searchInputEncoded
    }
  }

  const scrollHandler = (e: any) => {
    if (!timer) {
      setTimer(
        setTimeout(() => {
          if (bodyElement && typeof window !== undefined) {
            let scrollTop =
              window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop ||
              0
            if (
              scrollTop > 250 &&
              !bodyElement.classList.contains('menu-open')
            ) {
              bodyElement.classList.add('menu-fixed')
              if (scrollTop > 450) {
                bodyElement.classList.add('menu-visible')
                if (scrollTop > 600) {
                  bodyElement.classList.add('popup-visible')
                }
              } else {
                bodyElement.classList.remove('menu-visible')
              }
            } else {
              bodyElement.classList.remove('menu-fixed')
            }
          }
          setTimer(null)
        }, 50)
      )
    }
  }

  useEffect(() => {
    if (typeof document !== undefined) {
      bodyElement = document.querySelector('body')
    }
    if (typeof window !== undefined) {
      window.addEventListener('scroll', scrollHandler)
    }
    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [])

  useEffect(() => {
    renderAppropriateNav()
  }, [props.locale])

  const createTextBasedOnType = (item: any) => {
    if (item && item.__typename === ModuleNames.TOPIC_CTA) {
      return item.text
    }
  }

  const getMobileDrawerMicrocopy = () => {
    if (
      dynamicData &&
      dynamicData.allContentfulMicrocopySet &&
      dynamicData.allContentfulMicrocopySet.edges
    ) {
      return matchLocaleToMicrocopy(
        dynamicData.allContentfulMicrocopySet.edges,
        'Navigation',
        navEntryLocale
      )
    }
  }

  useEffect(() => {
    renderAppropriateNav()
  }, [props.locale])

  const generateHighlightLinks = (highlightedLinks: any, isNested: boolean) => {
    return highlightedLinks.map((highlightLink: any, r: number) => {
      const highlightLinkHref = highlightLink
        ? createLinkBasedOnType(highlightLink)
        : '/'
      const link = (
        <Button
          icon={true}
          href={highlightLinkHref}
          text={highlightLink.text ? highlightLink.text : ''}
          type="text-link"
          size="small"
          theme="light"
          className={`Menu-deepItem--Blue-Link ${
            isNested ? ' Menu-deepItem--Blue-Link-Nested' : ''
          }`}
          key={`${highlightLink.id}--${r}`}
        />
      )
      if (isNested) {
        return link
      } else {
        return <li key={`${highlightLink.id}--${r}`}>{link}</li>
      }
    })
  }

  const dynamicNav = () => {
    let menuData
    if (typeof window == `undefined`) {
      menuData = renderAppropriateNav()
    } else {
      menuData = contentfulNavigation
    }
    const menuGroups =
      menuData &&
      typeof menuData === 'object' &&
      menuData !== null &&
      menuData.hasOwnProperty('menuGroups') &&
      menuData.menuGroups &&
      menuData.menuGroups.length !== 0
        ? menuData.menuGroups
        : []

    const primaryCta =
      menuData &&
      typeof menuData === 'object' &&
      menuData !== null &&
      menuData.hasOwnProperty('primaryCta') &&
      menuData.primaryCta
        ? menuData.primaryCta
        : {}

    let dropdownType, mobileCTAText, mobileSearchText, languageDisplayName

    languageDisplayName = returnMatchingLocaleInfo(navEntryLocale)
    if (languageDisplayName === LocaleCodes.ENGLISH.name) {
      languageDisplayName = 'English'
    }

    const buttonWidth =
      primaryCta &&
      primaryCta.externalUrl &&
      primaryCta.text &&
      primaryCta.text.length
        ? 10 * primaryCta.text.length
        : 150

    const mobileDrawerMicrocopy = getMobileDrawerMicrocopy()
    const mobileCTAMicrocopy = getMicrocopyWithoutNumber(
      mobileDrawerMicrocopy,
      'mobileCTA',
      'text'
    )
    const mobileSearchMicrocopy = getMicrocopyWithoutNumber(
      mobileDrawerMicrocopy,
      'search',
      'text'
    )
    if (
      mobileCTAMicrocopy &&
      mobileCTAMicrocopy[0] &&
      mobileCTAMicrocopy[0].value
    ) {
      mobileCTAText = mobileCTAMicrocopy[0].value
    }
    if (
      mobileSearchMicrocopy &&
      mobileSearchMicrocopy[0] &&
      mobileSearchMicrocopy[0].value
    ) {
      mobileSearchText = mobileSearchMicrocopy[0].value
    }

    return (
      <HeaderContainer $buttonWidth={buttonWidth}>
        <header className="MainHeader js-MainHeader MainHeader--rebranded">
          <div className="MainHeader-container">
            <a
              href={localizedHomepageLink(navEntryLocale)}
              className="MainHeader-logo"
              rel="noopener"
            >
              <img
                className="MainHeader-logo--maxi"
                src={full_logo}
                alt="UiPath Logo"
              />
            </a>
            <div className="MainHeader-nav-menu-button-container">
              <div className="MainHeader-middle-nav-container">
                <div className="MainHeader-topBar">
                  <div className="MainHeader-topBar-links">
                    {menuData &&
                      menuData.topHatLinks &&
                      menuData.topHatLinks.map((topHatLink: any, j: number) => {
                        let target
                        if (topHatLink.externalUrl) {
                          if (isExternalURL(topHatLink.externalUrl)) {
                            target = '_blank'
                          } else {
                            target = '_self'
                          }

                          return (
                            <a
                              className={`MainHeader-contactButton`}
                              href={topHatLink.externalUrl}
                              target={target}
                              rel="noopener"
                              key={`topHatLink--${j}`}
                            >
                              {topHatLink.text}
                            </a>
                          )
                        }
                      })}
                  </div>

                  <nav className="MainHeader-menuContainer MainHeader-menuContainer--languageSwitcher">
                    <ul className="Menu-list Menu-list--languageSwitcher">
                      <li>
                        <button
                          className="Menu-item js-Menu-item Menu-item--languageSwitcher"
                          id="MainHeader-MobileLanguageSwitcher"
                          onClick={handleAccordionOpen}
                        >
                          {languageDisplayName}
                        </button>
                        <ul className="Menu-deepList Menu-deepList--languageSwitcher">
                          {LanguageSelect.rows.map((row: any, i: number) => {
                            // Don't show selected locale in dropdown
                            if (navEntryLocale !== row.code) {
                              return (
                                <li key={`language-select--${i}`}>
                                  <a
                                    className="LanguageSwitcher-button"
                                    hrefLang={row.lang}
                                    data-language={row.lang}
                                    href={row.href}
                                    rel="noopener"
                                  >
                                    <span>{row.title}</span>
                                  </a>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <nav className="MainHeader-menuContainer">
                  <ul className="Menu-list">
                    {menuGroups.map((menuGroup: MenuGroupProps, i: number) => {
                      const parentLinkHref = menuGroup.parentLink
                        ? createLinkBasedOnType(menuGroup.parentLink)
                        : '/'

                      let highlightedLinksArr = []

                      if (menuGroup.highlightedLinks) {
                        highlightedLinksArr.push(
                          generateHighlightLinks(
                            menuGroup.highlightedLinks,
                            false
                          )
                        )
                      }

                      let hasPages =
                        menuGroup.pages && Array.isArray(menuGroup.pages)

                      let numMenuGroups =
                        hasPages &&
                        menuGroup.pages.filter(
                          (item: any) =>
                            item.__typename === ModuleNames.MENU_GROUP
                        )

                      let greyColumnAlignment = ''
                      let greyColumns = 0
                      if (
                        menuGroup.greyColumns &&
                        menuGroup.greyColumnAlignment
                      ) {
                        greyColumns = parseInt(menuGroup.greyColumns)
                        greyColumnAlignment = menuGroup.greyColumnAlignment
                      }

                      if (
                        (menuGroup.groupStyle &&
                          menuGroup.groupStyle === 'Megamenu') ||
                        numMenuGroups.length > 0
                      ) {
                        dropdownType = 'Megamenu'
                      } else {
                        dropdownType = 'Simple'
                      }

                      let isFourColumns =
                        menuGroup.newGroupName === 'Solutions' &&
                        dropdownType === 'Megamenu'

                      const megamenuSubGroups = hasPages
                        ? menuGroup.pages.map(
                            (megamenuPage: any, n: number) => {
                              if (
                                megamenuPage.__typename ===
                                ModuleNames.MENU_GROUP
                              ) {
                                const productGroupNames = [
                                  'Product',
                                  'Produkt',
                                  'Produits',
                                  'Producto',
                                  '제품',
                                  '製品',
                                ]
                                const isProduct = productGroupNames.includes(
                                  menuGroup.newGroupName
                                )

                                let highlightedSubMenuLinksArr = []

                                if (megamenuPage.highlightedLinks) {
                                  highlightedSubMenuLinksArr.push(
                                    generateHighlightLinks(
                                      megamenuPage.highlightedLinks,
                                      true
                                    )
                                  )
                                }

                                let labelColor = megamenuPage.groupNameColor
                                  ? `--${megamenuPage.groupNameColor.toLowerCase()}`
                                  : ''

                                const columnsClassName = isFourColumns
                                  ? 'Menu-container--fourColumns'
                                  : 'Menu-container--sixColumns'

                                return (
                                  <li
                                    key={`${megamenuPage.newGroupName}--${n}`}
                                    ref={!isProduct ? sixColumns : undefined}
                                    className={`Menu-container--rightItems Menu-container--rightItems--borderTopBottom ${columnsClassName}`}
                                  >
                                    <h4
                                      className={`Menu-container-label Menu-container-label${labelColor}`}
                                    >
                                      {megamenuPage.newGroupName}
                                    </h4>
                                    {megamenuPage.pages &&
                                      megamenuPage.pages.map(
                                        (page: any, j: number) => {
                                          let target,
                                            subtitle,
                                            classes,
                                            isPartnerLogin
                                          const linkHref =
                                            createLinkBasedOnType(page)
                                          const linkTitle =
                                            createTextBasedOnType(page)
                                          if (isExternalURL(linkHref)) {
                                            target = '_blank'
                                          } else {
                                            target = '_self'
                                          }

                                          if (
                                            page.__typename ===
                                            ModuleNames.TOPIC_CTA
                                          ) {
                                            if (page.description) {
                                              subtitle = page.description
                                            }
                                            if (
                                              page.description ===
                                                'Business Partner Portal Sign In' ||
                                              page.description ===
                                                'Technology Partner Portal Sign In'
                                            ) {
                                              isPartnerLogin = true
                                              classes =
                                                'Menu-deepItem--microText palm-block'
                                            }
                                          }

                                          return (
                                            <a
                                              key={`${linkTitle}--${j}`}
                                              className="Menu-deepItem Menu-deepItem--noTLRPadding Menu-deepItem--fontStyle"
                                              href={linkHref}
                                              rel="noopener"
                                              target={target}
                                            >
                                              {isPartnerLogin ? (
                                                <p className="Menu-deepItem--microText Menu-Description-Text--IsPartnerLogin">
                                                  {subtitle}
                                                </p>
                                              ) : (
                                                <>
                                                  {formatInlineCode(linkTitle)}
                                                  <p className="Menu-deepItem--microText">
                                                    {subtitle}
                                                  </p>
                                                </>
                                              )}
                                            </a>
                                          )
                                        }
                                      )}
                                    {highlightedSubMenuLinksArr}
                                  </li>
                                )
                              } else {
                                let target
                                const linkHref =
                                  createLinkBasedOnType(megamenuPage)
                                const linkTitle =
                                  createTextBasedOnType(megamenuPage)
                                if (isExternalURL(linkHref)) {
                                  target = '_blank'
                                } else {
                                  target = '_self'
                                }
                                return (
                                  <li key={`${linkTitle}--${n}`}>
                                    <a
                                      className="Menu-deepItem"
                                      href={linkHref}
                                      rel="noopener"
                                      target={target}
                                    >
                                      {linkTitle}
                                    </a>
                                  </li>
                                )
                              }
                            }
                          )
                        : []

                      // Top level menu title
                      return (
                        <li
                          className={
                            dropdownType === 'Megamenu'
                              ? 'Menu-item--leftMenu'
                              : undefined
                          }
                          key={`${menuGroup.newGroupName}--Megamenu--${i}`}
                        >
                          {menuGroup.parentLink && parentLinkHref ? (
                            <a
                              href={parentLinkHref}
                              className="Menu-item js-Menu-item anchor"
                            >
                              {menuGroup.newGroupName}
                            </a>
                          ) : (
                            <p className="Menu-item js-Menu-item anchor">
                              {menuGroup.newGroupName}
                            </p>
                          )}

                          <button
                            onClick={handleAccordionOpen}
                            className="Menu-item js-Menu-item desktop--hide"
                          >
                            {menuGroup.newGroupName}
                          </button>
                          {dropdownType === 'Simple' ? (
                            <ul className="Menu-deepList">
                              {[megamenuSubGroups, ...highlightedLinksArr]}
                            </ul>
                          ) : (
                            <MegamenuContainer
                              className={`Menu-deepList Menu-deepList--leftMenu ${
                                greyColumns && greyColumnAlignment
                                  ? ''
                                  : ' Menu-deepList--noBgGradient Menu-deepList--bgWhite'
                              }`}
                              $megamenuType={menuGroup.newGroupName}
                              $numMenuGroups={numMenuGroups.length}
                              $greyColumnAlignment={greyColumnAlignment}
                              $greyColumns={greyColumns}
                              ref={
                                menuGroup.newGroupName === 'Support & Services'
                                  ? leftMostMenu
                                  : undefined
                              }
                            >
                              <li className="Menu-deepList--leftMenuContainerFull">
                                <ul>
                                  <li className="Menu-container--rightItems Menu-container--fullWidth Menu-container--containerBackground">
                                    <ul
                                      className={`Menu-container-menuItems ${
                                        isFourColumns
                                          ? 'Menu-container--fourColumnWrapper'
                                          : ''
                                      }`}
                                    >
                                      {megamenuSubGroups}
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </MegamenuContainer>
                          )}
                        </li>
                      )
                    })}
                    {/* Search placeholder */}
                    <HeaderSearch
                      className="headerSearch"
                      data-cy="headerSearch"
                      onClick={handleSearchClick}
                    >
                      <a className="MainHeader-gcsSearchButton searchIcon">
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          xmlnsXlink="https://www.w3.org/1999/xlink"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                        >
                          <defs>
                            <path
                              id="search2-a"
                              d="M10.741 10.464l3.803 3.802a.67.67 0 11-.948.948L9.794 11.41A6.03 6.03 0 116.03.67a6.03 6.03 0 014.711 9.794zm-4.711.926a4.69 4.69 0 100-9.38 4.69 4.69 0 000 9.38z"
                            ></path>
                          </defs>
                          <use fillRule="evenodd" xlinkHref="#search2-a"></use>
                        </svg>
                      </a>
                      <a className="MainHeader-gcsSearchButton searchText">
                        {mobileSearchText}
                      </a>
                    </HeaderSearch>
                  </ul>
                </nav>
                {primaryCta && primaryCta.externalUrl && primaryCta.text && (
                  <Button
                    icon={true}
                    href={primaryCta.externalUrl}
                    text={primaryCta.text}
                    type="primary"
                    size="small"
                    theme="dark"
                    className="MainHeader-CTA--Mobile"
                  />
                )}
              </div>
              {primaryCta && primaryCta.externalUrl && primaryCta.text && (
                <a
                  className="MainHeader-CTA Button Button--primary"
                  href={primaryCta.externalUrl}
                  rel="noopener"
                >
                  {primaryCta.text}
                </a>
              )}
            </div>
            <Search open={showSearch} handleSearchClick={handleSearchClick} />
            <div className="MainHeader-barActions">
              <Typography
                type="captionAlt"
                className="MainHeader-barActions-Close-Text"
              >
                Close
              </Typography>
              <button
                className="MainHeader-menuButton js-MainHeader-menuButton"
                onClick={handleMenuOpen}
              >
                <figure className="MainHeader-menuButtonIcon">menu</figure>
              </button>
            </div>
          </div>
        </header>
      </HeaderContainer>
    )
  }

  if (dynamicData) {
    return dynamicNav()
  } else {
    return null
  }
}

export default Header

import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

//global styles injected to entire application
export const GlobalStyle = createGlobalStyle`
  ${normalize}

  //Nato Sans font from Google Fonts - Japanese & Korean
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Icons-Essentials';
    font-display: swap;
    src: url('/steam-fonts/Icons-Essentials.woff') format('woff');
  }


  .overflow-hidden {
    overflow: hidden;
  }

  #drift-widget-container, #drift-widget {
    z-index: 2147483644 !important;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #000b23;
  }
  h1 {
    color: rgb(0, 0, 0);
    font-size: 56px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 64px;
    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 48px;
      line-height: 48px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -1px;
      font-weight: 600;
    }
  }
  h2 {
    margin: 0;
    font-size: 80px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 88px;
  }
  h3 {
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 44px;
    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 32px;
      line-height: 36px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0px;
    }
  }
  h4 {
    margin: 0;
    font-size: 48px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 56px;
  }
  h5 {
    margin: 0;
    font-size: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 48px;
  }
  h6 {
    margin: 0;
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 36px;
  }
  p {
    margin: 0;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
  }
  li {
    font-family: 'Inter', sans-serif;
  }
  a {
    color: #0067DF;
    text-decoration: none;
    &:hover,&:focus {
      text-decoration: underline;
      color: #0067DF;
      outline: none;
    }
  }
  .Lead {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 36px;
    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 20px;
      line-height: 28px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 28px;
    }
  }

  .LeadXS, .LeadXS p {
    color: rgb(0, 0, 0);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 32px;
    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 18px;
      line-height: 28px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 24px;
    }
  }
  .LineBreak {
    display: block;
    min-height: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      display: inline;
    }
  }
  .InlineItalic {
    font-style: italic;
  }
  .Eyebrow {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: bold;
  }
  .Black {
    color: #000b23;
  }
  .Align--Center {
    text-align: center;
  }
  .Align--Left {
    text-align: left;
  }
  .Align--Right {
    text-align: right;
  }
  .Align--Justify {
    text-align: justify;
  }
  .Text-Style--Subheading {
    color: ${(p) => p.theme.webMktPalette.text.primaryBlack};
    font-size: 20px;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 32px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 24px;
    }
  }
  .Secondary-CTA--Arrow,.Primary-CTA--Arrow,.CTA--Icon {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 24px;
    padding: 15px 21px 15px 24px;
    border-radius: 0;
    img {
      padding-left: 24px;
    }
  }
  .MuiContainer-maxWidthSm {
    max-width: 840px !important;
  }
  .MainHeader--rebranded .Menu-item {
    text-decoration: none;
    cursor: default;
    &.hasLink {
      cursor: pointer;
    }

    &:not(.Menu-item--languageSwitcher) {
      font-weight: bold;
    }
  }

  a,
  h4,
  li,
  p,
  span {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .MainHeader-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  canvas,
  video {
    *display: inline;
    *zoom: 1;
  }
  a:focus {
    outline: #333 dotted thin;
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
  }
  button {
    vertical-align: middle;
    *overflow: visible;
  }
  @media print {
    * {
      color: #000 !important;
      text-shadow: none !important;
      background: 0 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    a,
    a:visited {
      text-decoration: underline;
    }
    a[href]:after {
      content: ' (' attr(href) ')';
    }
    img {
      page-break-inside: avoid;
      max-width: 100% !important;
    }
    @page {
      margin: 0.5cm;
    }
    p {
      orphans: 3;
      widows: 3;
    }
  }
  p {
    margin: 0 0 10px;
  }
  h4 {
    margin: 10px 0;
    text-rendering: optimizelegibility;
  }
  ul ul {
    margin-bottom: 0;
  }
  button {
    line-height: 20px;
  }
  .close {
    float: right;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
  .close:focus,
  .close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
  button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
  }
  .nav {
    margin-bottom: 20px;
    margin-left: 0;
    list-style: none;
  }
  .nav > li > a {
    display: block;
  }
  .nav > li > a:focus,
  .nav > li > a:hover {
    text-decoration: none;
    background-color: #eee;
  }
  .nav > li > a > img {
    max-width: none;
  }
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }
  @keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .content {
    padding: 60px 0;
    margin: 0 auto;
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media (max-width: 962px) {
    .footer .company,
    .footer .product,
    .footer .support {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    .footer .company,
    .footer .product,
    .footer .support {
      margin-bottom: 0;
    }
  }
  li,
  p {
    line-height: 1.4;
  }
  img {
    min-height: 0;
  }
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  a.close {
    background: url('//blog.uipath.com/hs-fs/hub/416323/file-1132333736-gif/close.gif')
      top left no-repeat;
    cursor: pointer;
    height: 13px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 13px;
  }
  a.close:hover {
    background-position: 0 -13px;
  }
  .arrow {
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(64, 64, 64, 0.8);
    position: absolute !important;
    width: 0;
    height: 0;
  }
  .footer .company,
  .footer .product,
  .footer .support {
    margin-bottom: 20px;
  }
  .footer {
    color: #fff;
    opacity: 0.9;
  }
  .footer ul {
    padding: 0;
    margin: 0;
  }
  .footer li {
    list-style: none;
    margin-left: 0;
  }
  .footer a {
    color: #9bbfd8;
    text-decoration: none;
    -webkit-transition: color 100ms linear;
    transition: color 100ms linear;
  }
  .footer a:hover {
    color: #fff;
    text-decoration: none;
  }
  .button {
    margin: 6px;
    padding: 8px 16px;
    color: #fff;
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: color 0.2s ease, background-color 0.2s ease;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
  .Button:hover {
    text-decoration: none;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  img {
    pointer-events: none;
  }
  :focus {
    outline: 0;
  }
  .logo {
    margin-top: 29px;
  }
  .footer {
    margin-top: 55px;
    margin-bottom: 45px;
    padding-bottom: 45px;
  }
  .footer a {
    font-family: Poppins, sans-serif;
    color: #cdcdd4 !important;
    font-size: 14px !important;
  }
  .footer li {
    font-family: Poppins, sans-serif;
    line-height: 2;
  }
  @media screen and (min-width: 1200px) {
    .content {
      padding: 50px 0;
    }
  }
  .content {
    overflow: visible !important;
  }
  @media (max-width: 430px) {
    .content {
      height: 57px;
      overflow: visible !important;
      padding: 50px 0;
      display: inline;
    }
  }
  @media (min-width: 320px) and (max-width: 1199px) {
    .footer {
      margin-top: 15px;
      margin-bottom: 25px;
      padding-bottom: 25px;
      text-align: left;
    }
  }
  @media (min-width: 430px) and (max-width: 767px) {
    .content {
      height: 57px;
      overflow: visible !important;
      padding: 50px 0;
      display: inline;
    }
  }
  @media (min-width: 1025px) and (max-width: 1201px) {
    .content {
      height: 57px;
      overflow: visible !important;
      padding: 50px 0;
      display: inline;
    }
  }
  li,
  p {
    font-size: 18px;
    color: #131d40;
  }
  .pdf {
    margin-top: 0;
  }
  canvas,
  video {
    display: inline-block;
    vertical-align: baseline;
  }
  a {
    background-color: transparent;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  img {
    border: 0;
    height: auto;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button {
    color: inherit;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
  }
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-text-stroke: 0.25px;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: 'kern' 1;
    font-feature-settings: 'kern' 1;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: #08131f;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    width: 100%;
    height: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  figure,
  footer,
  header,
  menu,
  nav,
  section {
    display: block;
  }
  figure {
    margin: 0;
  }
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    font-family: Inter, sans-serif;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
  }
  h4 {
    color: #002855;
    font-size: 20px;
  }
  p,
  span {
    font-family: Inter, sans-serif;
  }
  p {
    line-height: 1.6;
    color: rgba(0, 40, 85, 0.5);
  }
  a {
    cursor: pointer;
    font-family: Inter, sans-serif;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  a:focus,
  a:hover {
    color: #002855;
    text-decoration: none;
  }
  .Button {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0.7em 1.3em;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
    background-color: #fa4616;
    border-color: #fa4616;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    -webkit-transition: color 0.15s ease, border-color 0.15s ease,
      background-color 0.15s ease;
    transition: color 0.15s ease, border-color 0.15s ease,
      background-color 0.15s ease;
  }
  .Button:visited {
    color: #fff;
  }
  .Button--primary {
    -webkit-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
    -webkit-transition: -webkit-transform 0.05s ease;
    transition: -webkit-transform 0.05s ease;
    transition: transform 0.05s ease;
    transition: transform 0.05s ease, -webkit-transform 0.05s ease;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .Button--primary:hover {
    color: #fff;
    -webkit-transform: scale(1.075) translateZ(0);
    transform: scale(1.075) translateZ(0);
    text-decoration: none;
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.25, 1.6, 0.6, 1.4);
    transition: -webkit-transform 0.2s cubic-bezier(0.25, 1.6, 0.6, 1.4);
    transition: transform 0.2s cubic-bezier(0.25, 1.6, 0.6, 1.4);
    transition: transform 0.2s cubic-bezier(0.25, 1.6, 0.6, 1.4),
      -webkit-transform 0.2s cubic-bezier(0.25, 1.6, 0.6, 1.4);
  }
  .Button--big {
    padding: 0.8em 2.5em;
    font-size: 18px;
    font-weight: 600;
  }
  @media screen and (min-width: 1025px) {
    .Button {
      font-size: 16px;
    }
    .Button--big {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 1025px) {
    .Button {
      font-size: 18px;
    }
  }

  .Link {
    font-family: Inter, sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: #0085ca;
    text-decoration: none;
  }

  body.menu-open .MainHeader-barActions {
    @media screen and (max-width: 1024px) {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
    }
  }
  body.menu-open .MainHeader--rebranded .MainHeader-menuButtonIcon:after,
  body.menu-open .MainHeader--rebranded .MainHeader-menuButtonIcon:before {
    background-color: #000000;
  }
  .MainHeader-mobileContact,
  .MainHeader-mobileContactBackground {
    display: none;
  }
  body.menu-open .MainHeader-mobileContact,
  body.menu-open .MainHeader-mobileContactBackground {
    display: block;
  }
  @media screen and (min-width: 1025px) {
    body.menu-open .MainHeader-mobileContact,
    body.menu-open .MainHeader-mobileContactBackground {
      display: none;
    }
  }
  .MainHeader-barActions-Close-Text {
    display: none;
    color: #000000;
  }
  body.menu-open .MainHeader-barActions-Close-Text {
    @media screen and (max-width: 1025px) {
      display: block;
    }
  }
  body.menu-open .MainHeader-logo {
    @media screen and (max-width: 1025px) {
      display: none;
    }
  }
  body.menu-open
    .MainHeader--rebranded
    .MainHeader-menuButton
    .MainHeader-menuButtonIcon:before {
    top: 50%;
  }
  @media screen and (max-width: 1024px) {
    body.menu-open .MainHeader-topBar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .MainHeader-menuContainer.MainHeader-menuContainer--languageSwitcher {
    border-bottom: none;
  }
  .Menu-list {
    width: calc(100% + 32px);
    margin: 0 -16px;
  }
  @media screen and (min-width: 1025px) {
    .Menu-list {
      width: auto;
      height: 100%;
      margin: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      z-index: 99;
    }
    .Menu-list > li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin: 8px 0;
    }
    .MainHeader--rebranded .Menu-list > li {
      margin: 0;
    }
    .Menu-list--languageSwitcher > li {
      height: 24px;
    }
    .Menu-list > li:hover .Menu-item:after {
      opacity: 1;
    }
    .Menu-list > li:hover .Menu-deepList {
      visibility: visible;
      -webkit-transition: visibility 0.2s 0.2s;
      transition: visibility 0.2s 0.2s;
    }
  }
  .Menu-item {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 14px 16px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    line-height: 1.1;
    color: #08131f;
    -webkit-transition: color 0s ease, background-color 0s ease;
    transition: color 0s ease, background-color 0s ease;
  }
  .MainHeader--rebranded .Menu-item {
    font-size: 16px;
    line-height: 24px;
    color: #273139;
    padding: 16px 0;
    text-transform: none;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-item {
      padding: 0 8px 16px;
      margin: 0;
      padding: 0 8px 4px;
      font-size: 14px;
    }
    .MainHeader--rebranded .Menu-item:hover {
      color: #0067df;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-item {
      padding: 0 8px 4px;
      font-size: 14px;
    }
  }
  @media screen and (min-width: 1281px) {
    .MainHeader--rebranded .Menu-item {
      padding: 0 12px 12px;
      font-size: 16px;
    }
    .MainHeader--rebranded .Menu-list > li {
      padding: 8px 0 12px 0;
      margin: 0;
    }
  }
  .Menu-item:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 4px;
    right: 16px;
    width: 7px;
    height: 7px;
    margin: auto 0;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  }
 
  .Menu-item.is-active {
    background-color: #0085ca;
    color: #fff;
    -webkit-transition: color 0.3s ease, background-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .MainHeader--rebranded .Menu-item.is-active {
    color: #0067df;
    background-color: #fff;
  }
  .MainHeader--rebranded .Menu-item.is-active:before {
    border-color: #000000;
  }
  .Menu-item.is-active:before {
    top: 8px;
    border-color: #f5f8fa;
    -webkit-transform: rotate(-45deg) scale(-1);
    transform: rotate(-45deg) scale(-1);
  }
  .Menu-item.is-active + .Menu-deepList {
    display: block;
  }
  .MainHeader--rebranded .Menu-item.is-active + .Menu-deepList {
    background-color: #ffffff;
  }
  @media screen and (min-width: 1025px) {
    .Menu-item {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      height: auto;
      padding: 16px 10px;
      font-size: 11px;
      text-align: center;
    }
    .Menu-item:before {
      content: none;
    }
    .Menu-item:after {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 12px;
      height: 2px;
      opacity: 0;
      background-color: #0085ca;
      -webkit-transition: opacity 0.2s ease;
      transition: opacity 0.2s ease;
    }
    .MainHeader--rebranded .Menu-item:after {
      bottom: -13px;
      height: 1px;
      z-index: 1;
    }
    .Menu-item.is-active {
      background-color: transparent;
      color: #08131f;
    }
    .Menu-item.is-active:after {
      content: '';
      -webkit-transition: opacity 0.1s ease-in;
      transition: opacity 0.1s ease-in;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-item {
      padding: 16px 12px;
      font-size: 12px;
    }
    .Menu-item:after {
      left: 12px;
      right: 12px;
    }
  }
  @media screen and (min-width: 1440px) {
    .Menu-item {
      padding: 16px;
      font-size: 14px;
    }
    .Menu-item:after {
      left: 16px;
      right: 16px;
    }
  }
  .Menu-deepList {
    display: none;
  }
  
  @media screen and (min-width: 1025px) {
    .Menu-deepList {
      position: absolute;
      top: 100%;
      left: -8px;
      display: block;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: #fff;
      -webkit-box-shadow: 1px 1px 8px 0 rgba(8, 19, 31, 0.25);
      box-shadow: 1px 1px 8px 0 rgba(8, 19, 31, 0.25);
      visibility: hidden;
      -webkit-transition: visibility 0s 0.005s;
      transition: visibility 0s 0.005s;
    }
    .MainHeader--rebranded .Menu-deepList {
      border-top: 1px solid #f7f7f7;
      min-width: 260px;
      -webkit-box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
      box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
      padding: 8px 0;
    }
    .MainHeader--rebranded
      .Menu-deepList.Menu-deepList--leftMenu.Menu-deepList--noBgGradient {
      background-image: none;
    }
    .MainHeader--rebranded
      .Menu-deepList.Menu-deepList--leftMenu.Menu-deepList--bgWhite {
      background: #fff;
    }
    .Menu-deepList > li {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-deepList {
      left: -8px;
    }
  }
  .Menu-container-menuItems  {
    padding: 8px 0;
  }
  .Menu-deepItem {
    position: relative;
    display: block;
    padding: 12px 36px;
    font-size: 14px;
    font-weight: 600;
    color: #08131f;
    text-decoration: none;
  }
  .Menu-deepItem:hover {
    text-decoration: none;
  }
  ul.Menu-deepList.Menu-deepList--leftMenu.Menu-deepList--leftMenuBg40Percent {
    background: linear-gradient(90deg, #fafafa calc(((100% - 1260px) / 2) + 1260px * 0.4), #ffffff calc(100% - 1260px));
    @media(max-width: 1260px) {
      background: linear-gradient(90deg, #fafafa calc(100% * 0.4), #ffffff 0);
    }
    @media(max-width: 1024px) {
      background: unset;
    }
  }
  a.CTA.Menu-deepItem--Blue-Link {
    padding: 8px 24px;
    font-weight: 400;
    font-size: 14px;
    @media(max-width: 1024px) {
      padding: 12px 32px;
    }
    .CTA__Arrow-Container {
      display: none;
    }
  }
  a.CTA.Menu-deepItem--Blue-Link-Nested {
    padding: 0;
    @media(max-width: 1024px) {
      padding: 12px 32px;
    }
  }
  .MainHeader--rebranded .Menu-deepItem {
    padding: 12px 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    white-space: normal;
    @media (max-width: 1024px) {
      background-color: #f7f7f7;
    }
  }
  .Menu-Description-Text--IsPartnerLogin {
    @media (max-width: 1024px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      white-space: normal;
      margin: 0;
    }
  }
  @media (max-width: 1024px) {
    p.Menu-deepItem--microText.palm-block {
      display: block !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      margin: 0 !important;
      color: rgba(0, 40, 85, 0.5);
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-deepItem {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      padding: 8px 24px;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-deepItem {
      padding: 12px 36px 12px 18px;
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 13px;
      -webkit-transition: color 0.1s ease, background-color 0.1s ease;
      transition: color 0.1s ease, background-color 0.1s ease;
    }
    .MainHeader--rebranded .Menu-deepItem {
      text-transform: none;
    }
    .Menu-deepItem:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      background-color: #fa4616;
      opacity: 0;
      -webkit-transition: opacity 0.05s ease;
      transition: opacity 0.05s ease;
    }
    .Menu-deepList--leftMenu .Menu-deepItem:before {
      display: none;
    }
    .Menu-deepItem:hover {
      background-color: #0085ca;
      color: #fff;
      -webkit-transition: color 0.1s ease, background-color 0.1s ease;
      transition: color 0.1s ease, background-color 0.1s ease;
    }
    .MainHeader--rebranded .Menu-deepItem:hover {
      background-color: #0067df;
    }
    .MainHeader--rebranded .Menu-deepItem:hover:before {
      display: none;
    }
    .Menu-deepItem:hover:before {
      opacity: 1;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-deepItem {
      padding: 12px 64px 12px 21px;
      font-size: 12px;
    }
    .MainHeader--rebranded .Menu-deepList--leftMenu .Menu-deepItem:hover {
      background: 0 0;
      color: #0067df;
    }
    .MainHeader--rebranded .Menu-deepList--leftMenu .Menu-deepItem:hover p {
      color: #0067df;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-deepItem p {
      display: block;
      font-weight: 400;
      font-size: 12px;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-deepItem.Menu-deepItem--titleHidden {
      display: block;
      width: 100%;
      font-weight: 400;
      text-transform: uppercase;
      padding: 12px 32px 12px 28px;
      color: #273139;
      font-size: 12px;
      line-height: 16px;
      pointer-events: none;
    }
  }
  .Menu-container--leftItems {
    border-top: 1px solid #e1e2e4;
    padding-top: 20px;
    width: 96%;
    margin: 20px auto 0;
  }
  @media screen and (min-width: 1025px) {
    .Menu-container--leftItems {
      padding-top: 20px;
      margin-top: 0;
      padding-bottom: 16px;
    }
  }
  .Menu-container-label,
  .Menu-deepList-footer {
    display: none;
  }
  .Menu-container--rightItems--borderTopBottom {
    position: relative;
  }
  .Menu-container--rightItems--borderTopBottom:last-child:after {
    content: none;
  }
  .Menu-deepItem--microText:not(.Menu-Description-Text--IsPartnerLogin) {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-deepItem .Menu-deepItem--microText {
      font-size: 12px;
      line-height: 16px;
      color: #919295;
      margin-top: 4px;
      margin-bottom: 0;
    }
    .Menu-deepItem.Menu-deepItem--secondaryLink {
      color: #273139;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 16px;
      padding-bottom: 0;
    }
    .Menu-deepItem.Menu-deepItem--secondaryLink.secondaryLink__padding {
      padding-bottom: 24px;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-item--leftMenu {
      position: static;
    }
    .Menu-deepList.Menu-deepList--leftMenu {
      width: 100%;
      left: 0;
      overflow: hidden;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-container--leftItems {
      float: left;
      width: 20%;
      border-top: none;
      position: relative;
      z-index: 1;
    }
  }
  @media screen and (min-width: 1025px) and (min-width: 1025px) {
    .MainHeader--rebranded .Menu-container--leftItems ul li a {
      padding-left: 28px;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-container--rightItems {
      float: right;
      width: 70%;
      padding: 16px 24px 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .Menu-container--rightItems > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .Menu-container--rightItems.Menu-container--rightItemsWidth50Percent {
      width: 50%;
      padding: 16px 0 0;
    }
    .Menu-deepItem.Menu-deepItem--fontBig {
      padding: 12px 24px;
      font-size: 16px;
    }
    .Menu-container--leftItems.Menu-container--flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 50%;
    }
    .Menu-container--rightItems.Menu-container--fullWidth {
      width: 100%;
      float: none;
      padding: 0 8px 16px 8px;
    }
    .Menu-container--rightItems.Menu-container--fullWidth
      > ul.Menu-container-menuItems {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }

    @media screen and (min-width: 1025px) and (max-width: 1280px) {
      .Menu-container--rightItems.Menu-container--fullWidth > ul.Menu-container-menuItems.Menu-container--fourColumnWrapper {
        width: 992px;
        margin: 0 auto;
      }
    }

    .Menu-container-label {
      display: block;
      color: #b2b2b2;
      font-size: 12px;
      line-height: 16px;
      font-family: Inter, sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    @media screen and (min-width: 1025px) {
      .Menu-container-label {
        margin: 0 0 8px 0;
      }
    }
    .Menu-container-label--black {
      color: #000000;
    }
    .Menu-container-label--blue {
      color: #0067df;
    }
    .Menu-container-label--orange {
      color: #fa4616;
    }
    .Menu-container-label--red {
      color: #ed145b;
    }
    .Menu-container-label--purple {
      color: #933692;
    }
    .Menu-container-label--lightBlue {
      color: #38c6f4;
    }
    .Menu-container-label--green {
      color: #249b49;
    }
    .Menu-deepItem.Menu-deepItem--noTLRPadding {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .Menu-deepItem.Menu-deepItem--fontStyle {
      color: #273139;
      font-size: 16px;
      font-family: Inter, sans-serif;
      font-weight: 700;
      line-height: 24px;
      padding-bottom: 16px;
    }
    .Menu-deepItem.Menu-deepItem--fontStyle:hover:after {
      content: none;
    }
    .Menu-container--rightItems.Menu-container--twoColumns {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      vertical-align: top;
    }
    .Menu-container--rightItems.Menu-container--sixColumns {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      vertical-align: top;
    }

    @media screen and (min-width: 1025px) {
      .Menu-container--rightItems.Menu-container--fourColumns {
        width: 222px;
        box-sizing: content-box;
      }
      .Menu-container--rightItems.Menu-container--fourColumns .Menu-deepItem {
        padding-right: 2px;
      }
      .Menu-container--rightItems.Menu-container--fourColumns:first-of-type {
        padding: 16px 12px 16px 16px;
      }
      .Menu-container--rightItems.Menu-container--fourColumns:not(:first-of-type) {
        padding: 16px 12px 16px;
      }
    }

    @media screen and (min-width: 1281px) {
      .Menu-container--rightItems.Menu-container--fourColumns {
        width: 25%;
        box-sizing: border-box;
      }
    }
    .Menu-container--rightItems.Menu-container--rightItems--hideDesktop {
      display: none;
    }
    .Menu-deepList-footer {
      display: block;
      background-color: #f7f7f7;
    }
    .Menu-deepList-footerContainer {
      width: 100%;
      margin: 0 24px;
    }
    .Menu-deepList-footerCTA.Menu-deepList-footerCTA {
      position: relative;
      display: inline-block;
      padding: 24px 24px 24px 32px;
      color: #0067df;
      font-size: 16px;
      font-family: Inter, sans-serif;
      font-weight: 700;
      line-height: 20px;
    }
    .Menu-deepList-footerCTA.Menu-deepList-footerCTA:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 8px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background: url(https://www.uipath.com/hubfs/resources/Partner%20Locator/24x24_ArrowLong_Right.svg)
        no-repeat;
      width: 16px;
      height: 8px;
      color: #fff;
    }
    .Menu-deepList-footerCTA.Menu-deepList-footerCTA:hover {
      text-decoration: underline;
    }
    .Menu-item--leftMenu .Menu-item.is-active {
      color: #0085ca;
      background-color: #fff;
    }
    .MainHeader--rebranded
      .Menu-container--rightItems.Menu-container--twoColumns
      .Menu-deepItem {
      font-weight: 700;
      padding: 0 32px;
    }
    .MainHeader--rebranded
      .Menu-container--rightItems.Menu-container--twoColumns
      .Menu-deepItem:after {
      display: none;
    }
    .MainHeader--rebranded .Menu-container--leftItems .Menu-deepItem {
      font-weight: 700;
    }
    .MainHeader--rebranded .Menu-container--leftItems .Menu-deepItem:after {
      display: none;
    }
    .Menu-container--leftItems .Menu-deepItem.Menu-deepItem--titleHidden {
      font-weight: 400;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .Menu-deepList.Menu-deepList--leftMenu.Menu-deepList--leftMenuBg25Percent {
      padding-bottom: 0;
      .Menu-container--rightItems.Menu-container--containerBackground {
        padding: 0;
      }
      .Menu-deepList--leftMenuContainerFull {
        background: #ffffff;
        padding-right: 28px;
        box-sizing: border-box;
      }
      .Menu-container--rightItems {
        padding: 32px 20px 24px 20px;
        background: #ffffff;
        &:first-of-type {
          background: #fafafa;
        }
        .Menu-container-menuItems {
          padding-left: 10px;

        }
      }
    }
    .MainHeader--rebranded .Menu-item.is-active+.Menu-deepList {
      background-color: #f7f7f7;
    }
    .Menu-deepList.Menu-deepList--leftMenu
      .Menu-container--rightItems.Menu-container--containerBackground {
      position: relative;
    }
    .Menu-deepList.Menu-deepList--leftMenu.Menu-deepList--noBgGradient
      .Menu-container--rightItems.Menu-container--containerBackground {
      position: relative;
    }
    .Menu-deepList.Menu-deepList--leftMenu.Menu-deepList--noBgGradient
      .Menu-container--rightItems.Menu-container--containerBackground:after {
      content: none;
    }
  }
  @media screen and (min-width: 1025px) {
    .Menu-container--leftItems .Menu-deepItem.Menu-deepItem--secondaryLink {
      color: #273139;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
    .Menu-container--leftItems .Menu-deepItem.Menu-deepItem--secondaryLink:hover {
      text-decoration: underline;
    }
  }
  .Menu-container--leftItems .Menu-deepItem {
    padding-left: 20px;
  }
  .Menu-container--leftItems .Menu-deepItem.Menu-deepItem--titleHidden {
    padding-left: 28px;
  }
  .Menu-deepList--leftMenuContainerFull {
    max-width: 1260px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1280px) {
    .Menu-deepList-footerContainer {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  .LanguageSwitcher-button {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
    padding: 8px 16px 8px 4px;
    text-align: left;
  }
  .MainHeader--rebranded .LanguageSwitcher-button {
    padding: 8px 0;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .LanguageSwitcher-button {
      padding-right: 80px;
      padding-left: 24px;
      padding-bottom: 6px;
    }
  }
  .LanguageSwitcher-button > img {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    margin: 0 6px;
  }
  .LanguageSwitcher-button > span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: rgba(0, 40, 85, 0.5);
    line-height: 1;
  }
  .MainHeader--rebranded .LanguageSwitcher-button > span {
    font-size: 14px;
    color: #273139;
  }
  .LanguageSwitcher-button:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 4px;
    right: 0;
    width: 7px;
    height: 7px;
    margin: auto 0;
    border-bottom: 2px solid #4a4a4a;
    border-left: 2px solid #4a4a4a;
    border-radius: 1px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .Menu-item.Menu-item--languageSwitcher.is-active {
    color: #273139;
  }
  .Menu-item.Menu-item--languageSwitcher.is-active:before {
    top: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #273139;
  }
  .MainHeader--rebranded .LanguageSwitcher-button:before {
    border-bottom: 3px solid rgba(0, 40, 85, 0.5);
    border-left: 3px solid rgba(0, 40, 85, 0.5);
    width: 3px;
    height: 3px;
    left: 55px;
    top: 10px;
  }
  .Menu-item.Menu-item--languageSwitcher {
    color: #273139;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 12px;
  }
  .Menu-item.Menu-item--languageSwitcher:before {
    width: 0;
    height: 0;
    -webkit-transform: none;
    transform: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #273139;
    border-bottom: none;
    left: 80px;
    right: auto;
    bottom: 10px;
    content: '';
  }
  @media screen and (min-width: 1025px) {
    .Menu-item.Menu-item--languageSwitcher:before {
      left: auto;
      right: -20px;
      bottom: 1px;
    }
  }
  .Menu-item.Menu-item--languageSwitcher:hover {
    text-decoration: underline;
    color: #0067df;
  }
  @media screen and (min-width: 1025px) {
    .Menu-item.Menu-item--languageSwitcher:hover {
      color: #0067df;
    }
  }
  .Menu-deepList.Menu-deepList--languageSwitcher .LanguageSwitcher-button {
    padding: 16px 32px;
  }
  .Menu-deepList.Menu-deepList--languageSwitcher .LanguageSwitcher-button:before {
    display: none;
  }
  .MainHeader-menuContainer.MainHeader-menuContainer--languageSwitcher {
    margin: 0;
  }
  .MainHeader-menuContainer.MainHeader-menuContainer--languageSwitcher:before {
    display: none;
    content: none;
  }
  @media screen and (min-width: 1025px) {
    .Menu-item.Menu-item--languageSwitcher {
      padding: 0;
      font-size: 12px;
      margin-top: -6px;
    }
    .Menu-deepList.Menu-deepList--languageSwitcher {
      min-width: auto;
      width: 160px;
      left: -24px;
      top: 90%;
      background: #ffffff;
      -webkit-box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
      box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
      text-align: left;
      z-index: 1;
    }
    .Menu-deepList.Menu-deepList--languageSwitcher .LanguageSwitcher-button {
      padding: 10px 10px 9px 24px;
      display: block;
      position: relative;
    }
    .Menu-deepList.Menu-deepList--languageSwitcher
      .LanguageSwitcher-button:hover {
      background: #0067df;
    }
    .Menu-deepList.Menu-deepList--languageSwitcher
      .LanguageSwitcher-button:hover
      span {
      color: #fff;
    }
    .Menu-deepList.Menu-deepList--languageSwitcher
      .LanguageSwitcher-button
      span:after {
      position: absolute;
      content: '\\2192';
      opacity: 0;
      top: 14px;
      right: 10px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
    .Menu-deepList.Menu-deepList--languageSwitcher
      .LanguageSwitcher-button
      span:hover:after {
      opacity: 1;
    }
    .Menu-list.Menu-list--languageSwitcher {
      margin-right: 16px;
      padding-top: 9px;
      position: relative;
      top: 2px;
    }
    .Menu-list.Menu-list--languageSwitcher > li {
      padding: 0 24px 0 0;
      cursor: pointer;
    }
  }
  .Statement.Statement {
    padding: 32px 24px;
    background-color: #f5f8fa;
    text-align: center;
  }
  @media screen and (min-width: 1025px) {
    .Statement.Statement {
      padding: 40px 14px;
    }
  }
  @media screen and (min-width: 1025px) {
    .Statement.Statement {
      padding: 48px 16px;
    }
  }
  @media screen and (min-width: 1440px) {
    .Statement.Statement {
      padding: 56px 18px;
    }
  }
  h4 {
    padding: 0;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1.3;
  }
  .Webinars {
    max-width: 964px;
    margin: 0 auto;
    padding: 44px 16px 24px;
  }
  .MainHeader-CTA--Mobile {
    display: none !important;
  }
  @media screen and (max-width: 1024px) {
    body.menu-open {
      .MainHeader-nav-menu-button-container {
        display: flex;
        width: 100%;
      }
      .MainHeader-container {
        padding: 32px;
      }
      .MainHeader-barActions {
        top: 20px;
        right: 20px;
      }
    }
  }

  
  @media screen and (max-width: 1024px) {
    body.menu-open .MainHeader-CTA--Mobile {
      display: inline-flex !important;
    }
  }
  @media screen and (max-width: 767px) {
    body.menu-open .MainHeader--rebranded .MainHeader-CTA {
      display: none;
    }
  }
  body.menu-open .MainHeader-menuContainer {
    display: block;
  }
  body.menu-open .MainHeader-menuButton:after {
    opacity: 0;
  }
  body.menu-open .MainHeader-menuButton .MainHeader-menuButtonIcon:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: calc(50% - 2px);
  }
  body.menu-open .MainHeader-menuButton .MainHeader-menuButtonIcon:after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    bottom: calc(50% - 2px);
  }
  .MainFooterRev
    .MainFooterRev-container
    .MainFooterRev-column
    .MainFooterRev-deepList
    span {
    margin: 6px 0 !important;
  }
  .MainFooterRev
    .MainFooterRev-container
    .MainFooterRev-column
    .MainFooterRev-deepList
    span
    a {
    line-height: 1.45em !important;
  }
  body.menu-fixed .MainHeader {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    z-index: 6;
    transform: translateY(-150%) translateZ(0);
    transition: top 0s 0.25s, transform 0.15s ease;
  }
  body.menu-fixed.menu-visible .MainHeader {
    top: 0;
    transform: translateY(0) translateZ(0);
    transition: transform 0.25s ease;
  }
  body.menu-fixed .MainContainer,
  body.menu-fixed header + div {
    margin-top: 62px;
  }
  @media screen and (min-width: 1025px) {
    body.menu-fixed .MainContainer,
    body.menu-fixed header + div {
      margin-top: 102px;
    }
  }
  @media screen and (min-width: 1025px) {
    body.menu-fixed .MainContainer,
    body.menu-fixed header + div {
      margin-top: 107px;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-container .MainHeader-logo {
      z-index: 1;
      cursor: pointer;
      flex: 0 0 auto;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-logo > img {
      margin-top: 0 !important;
    }
  }
  @media (max-width: 1024px) {
    nav.MainHeader-menuContainer > ul.Menu-list {
      max-width: 100% !important;
      width: 100% !important;
      margin: 0 !important;
      box-sizing: border-box !important;
    }
  }
  @media (max-width: 1024px) {
    nav.MainHeader-menuContainer > ul.Menu-list .Menu-item.js-Menu-item.anchor {
      display: none !important;
    }
  }
  @media screen and (min-width: 1025px) {
    nav.MainHeader-menuContainer > ul.Menu-list .desktop--hide {
      display: none !important;
    }
  }
  @media (max-width: 1024px) {
    .MainHeader--rebranded .MainHeader-contactButton.contactMargin,
    .MainHeader--rebranded .MainHeader-contactButton.signInMargin {
      padding-left: 16px;
    }
  }

  .Menu-item.js-Menu-item.anchor {
    cursor: pointer;
  }
  .MainHeader--rebranded
    .MainHeader-menuContainer
    > ul.Menu-list
    > li.js-Hover
    > .Menu-item,
  .MainHeader--rebranded .Menu-item:hover {
    color: #0067df;
  }
  .scroll-lock {
    position: fixed;
    overflow-y: scroll;
  }

  .richText-styled-list {
    padding-left: 24px;
    padding-bottom: 24px;
  }

  ul.richText-styled-list {
    list-style: disc;
  }

  ol.richText-styled-list {
    list-style: decimal;
  }

  .richText-styled-list-items {
    margin: 16px 0;
  }


  .richText-styled-list-items > p {
    display: inline;
  }

  // Select and style every element with a lang attribute value starting with "ja" (Japaneese)):
  :lang(ja) {
    font-family: 'Noto Sans JP', sans-serif;
  }
  // Select and style every element with a lang attribute value starting with "ko" (Korean)):
  :lang(ko) {
    font-family: 'Noto Sans KR', sans-serif;
  }



`

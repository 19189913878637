// Constants
import { ModuleNames } from '../constants/componentNames.constants'
import {
  LocaleCodes,
  LanguageSelect,
  ResourceCategoryCodes,
} from '../constants/localization.constants'
import { getMicrocopyData } from '../utils/microcopy.utils'
import { generateBlogPostSlug } from '../utils/blog.utils'
import { mapLocale } from './pageCreation.utils'

// To see if two arrays match
export const arraysMatch = (arr1: any[], arr2: any[]) => {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  // Otherwise, return true
  return true
}

export const isResourceCategory = (type: string, number: number | string) => {
  return ResourceCategoryCodes[type]?.[number] !== undefined
}

// To Generate Blog Ads
export const generateAdData = (featuredResource: any) => {
  if (featuredResource) {
    let title,
      image,
      imageAlt,
      category,
      categoryNumber,
      cta,
      ctaText,
      ctaUrl,
      date,
      body

    let fr = featuredResource.featuredResource
      ? featuredResource.featuredResource
      : undefined

    if (fr && fr.__typename) {
      title = fr.title ? fr.title : ''
      date = fr.date ? new Date(fr.date) : ''
      if (fr.image) {
        if (fr.image.altText) imageAlt = fr.image.altText
        if (fr.image.image && fr.image.image.file && fr.image.image.file.url)
          image = fr.image.image.file.url
      }
      if (fr.category) {
        category = fr.category.title ? fr.category.title : ''
        categoryNumber = fr.category.number ? fr.category.number : ''
      }
      if (fr.teaserBody || fr?.seoMetadata?.pageMetaDescription) {
        body = fr.teaserBody || fr.seoMetadata.pageMetaDescription
      } else {
        body = ''
      }

      if (fr.slug && typeof window !== `undefined`) {
        ctaUrl =
          window.location.origin + fr.slug[0] === '/' ? fr.slug : `/${fr.slug}`
      } else {
        ctaUrl = ''
      }

      if (fr.__typename === ModuleNames.TEASER) {
        ctaUrl = fr.url1 ? fr.url1 : ''
      } else if (fr.__typename === ModuleNames.BLOG_POST) {
        ctaUrl =
          fr.slug && fr.blogCategory?.name
            ? generateBlogPostSlug(fr.slug, fr.blogCategory.name)
            : ''
      } else if (fr.__typename === ModuleNames.PAGE_RESOURCE_DEMO) {
        title = fr?.hero?.headline ? fr.hero.headline : ''
        if (fr.spotlight) {
          if (fr.spotlight?.video?.altText)
            imageAlt = fr.spotlight.video.altText
          if (fr.spotlight?.video?.image?.file?.url)
            image = fr.spotlight.video.image.file.url
        }
      }

      if (fr.microcopy && categoryNumber) {
        cta = getMicrocopyData(fr.microcopy, categoryNumber, 'primary', 'text')
      }

      if (
        isResourceCategory('Webinar', categoryNumber) ||
        (isResourceCategory('Events', categoryNumber) &&
          cta.length >= 2 &&
          date)
      ) {
        let now = new Date()
        let when = date && date >= now ? 'before' : 'after'
        if (cta[0].key && cta[0].key.includes(when)) ctaText = cta[0].value
        else if (cta[1].key && cta[1].key.includes(when)) ctaText = cta[1].value
      }
      if (!ctaText) {
        if (cta && cta[0] && cta[0].value) {
          ctaText = cta[0].value
        } else {
          ctaText = 'Read the blog'
        }
      }

      return {
        title,
        categoryNumber,
        category,
        image,
        imageAlt,
        ctaText,
        ctaUrl,
        body,
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export const localizedHomepageLink = (entryLocale: string) => {
  if (entryLocale && entryLocale !== LocaleCodes.ENGLISH.code) {
    return `/${entryLocale.substr(0, 2)}`
  } else {
    return '/'
  }
}

export const kebabCaseString = (originalStr: string) => {
  return originalStr
    .replace(/,/g, '')
    .replace(/&/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/\s/g, '-')
}

export const returnMatchingLocaleInfo = (entryLocale: string) => {
  if (entryLocale && LanguageSelect) {
    const localeInfo = LanguageSelect.rows.filter(
      (localeItem: any) => localeItem.code === entryLocale
    )
    if (localeInfo && localeInfo[0] && localeInfo[0].title) {
      return localeInfo[0].title
    } else {
      return 'English'
    }
  }
}

export const returnSelectedLocaleData = (
  data: any,
  requestedLocale: string
) => {
  return data.filter((item: any) => {
    const entryItem = item.node ? item.node : item
    const localeOfEntryObj = mapLocale(entryItem.entryLocale)
    const localeOfEntry =
      localeOfEntryObj && localeOfEntryObj.pageLocale
        ? localeOfEntryObj.pageLocale
        : LocaleCodes.ENGLISH.code
    // Need to match the selected locale of the announcement with the right field and then make sure it's the same locale as the page we're on
    return (
      entryItem.node_locale === localeOfEntry &&
      entryItem.node_locale === requestedLocale
    )
  })
}

export const delocalizeUrl = (url: string) => {
  const urlArray = url.split('/')
  let localeCodeIndex = -1
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('ko')
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('de')
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('fr')
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('ja')
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('pt')
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('es')
  if (localeCodeIndex === -1) localeCodeIndex = urlArray.indexOf('cn')
  if (localeCodeIndex !== -1) {
    urlArray.splice(localeCodeIndex, 1)
  }
  return urlArray.join('/')
}
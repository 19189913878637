export const ModuleNames = {
  HERO_POC: 'ContentfulHero',
  HERO: 'ContentfulComponentHero',
  PRODUCT_VALUE_PROP: 'ContentfulProductValuePropModule',
  PRODUCT_VIDEO_VALUE_PROP: 'ContentfulProductContentValueProp',
  VIDEO_SPOTLIGHT: 'ContentfulComponentSpotlight',
  MEDIA_TEXT: 'ContentfulComponentAssetText',
  MEDIA_ASSET: 'ContentfulComponentMediaAsset',
  RESOURCE_ROW: 'ContentfulComponentResourceRow',
  BENEFITS: 'ContentfulComponentBenefits',
  ICONS: 'ContentfulComponentIcons',
  TABLE: 'ContentfulComponentTable',
  GLOBAL_MEDIA_TEXT: 'ContentfulComponentAssetText',
  GLOBAL_FEATURE_CARD: 'ContentfulGlobalFeatureCard',
  GLOBAL_FEATURE_CARD_VERTICAL: 'ContentfulGlobalFeatureCardVertical',
  GLOBAL_DOWNLOAD_ASSET: 'ContentfulGlobalDownloadAsset',
  GLOBAL_SECTION_HIGHLIGHT: 'ContentfulGlobalSectionHighlight',
  GLOBAL_CONTENT_COLUMNS_POC: 'ContentfulGlobalContentColumns',
  GLOBAL_CONTENT_COLUMNS: 'ContentfulComponentColumns',
  GLOBAL_KEY_STATS: 'ContentfulComponentKeyStats',
  GLOBAL_KEY_STATS_POC: 'ContentfulGlobalKeyStats',
  GLOBAL_QUOTES_SLIDER: 'ContentfulGlobalQuoteSlider',
  GLOBAL_CONTENT_BLOCK_POC: 'ContentfulGlobalContentBlock',
  GLOBAL_CONTENT_BLOCK: 'ContentfulComponentContentBlock',
  GLOBAL_MAIN_CTA: 'ContentfulComponentMainCta',
  GLOBAL_MAIN_CTA_POC: 'ContentfulGlobalBottomCtaSection',
  GRID: 'ContentfulComponentGrid',
  DIVIDER: 'ContentfulComponentDivider',
  GRID_POC: 'ContentfulGrid',
  VIDEO: 'ContentfulProductVideoSection',
  TRY_PRODUCT_MODULE: 'ContentfulTryProductModule',
  CAROUSEL: 'ContentfulGlobalQuoteSlider',
  PRODUCT_DATA_TRIO: 'ContentfulGlobalKeyStats',
  IMAGE: 'ContentfulImage',
  MARKETO_FORM: 'ContentfulComponentMarketoForm',
  QUOTE: 'ContentfulComponentQuote',
  FEATURED_RESOURCE: 'ContentfulComponentFeaturedResource',
  SLIDER: 'ContentfulComponentSlider',
  PAGE_PRODUCT: 'ContentfulPageProduct',
  PAGE_FLEX: 'ContentfulPageFlex',
  TOPIC_CTA: 'ContentfulTopicCta',
  MENU_GROUP: 'ContentfulMenuGroup',
  RESOURCE_TABS: 'ContentfulComponentResourceTabs',
  PLATFORM_DIAGRAM: 'ContentfulComponentPlatformDiagram',
  COLLAPSIBLE_QUADRANT: 'ContentfulComponentCollapsibleQuadrant',
  BIO: 'ContentfulComponentBio',
  TILES: 'ContentfulComponentTiles',
  AUTOMATION_USE_CASE: 'ContentfulTopicAutomationUseCase',
  TEASER: 'ContentfulTeaser',
  BLOG_POST: 'ContentfulPageBlogPost',
  WRAPPER_ASSET: 'ContentfulWrapperAsset',
  WRAPPER_VIDEO: 'ContentfulWrapperVideo',
  PARAGRAPH_BLOCK: 'ContentfulComponentParagraphBlock',
  TOPIC_CUSTOMER_DETAILS: 'ContentfulTopicCustomerDetails',
  TOPIC_REGION: 'ContentfulTopicResourceRegion',
  TOPIC_INDUSTRY: 'ContentfulTopicResourceIndustry',
  TOPIC_PARTNER_DETAILS: 'ContentfulTopicPartnerDetails',
  TOPIC_RESOURCE_PRODUCT: 'ContentfulTopicResourceProduct',
  TOPIC_FAQ: 'ContentfulTopicFaq',
  TOPIC_AGENDA: 'ContentfulTopicAgenda',
  PAGE_MAIN_BLOG: 'ContentfulPageMainBlog',
  PAGE_RESOURCE_DEMO: 'ContentfulPageResourceDemo',
  PAGE_CUSTOMER_STORY: 'ContentfulPageResource',
  PAGE_SOLUTIONS: 'ContentfulPageSolutions',
  PAGE_ABOUT_RPA: 'ContentfulPageAboutRpa',
  PAGE_PRODUCT_DOWNLOAD: 'ContentfulPageDownload',
  PRODUCT_DOWNLOAD_COMPONENT: 'ContentfulComponentProductDownload',
  WRAPPER_PDF: 'ContentfulWrapperPdf',
  HOMEPAGE: 'ContentfulHomepage',
  PAGE_THANK_YOU: 'ContentfulPageThankYou',
  ANCHOR_LINK: 'ContentfulTopicAnchorLink',
  CONTENT_ROWS: 'ContentfulComponentContentRows',
  PAGE_PLACEHOLDER: 'ContentfulPagePlaceholder',
  PAGE_WEBINAR_RECORDING: 'ContentfulPageWebinarRecording',
  PAGE_ANALYST_REPORT: 'ContentfulPageAnalystReport',
  PAGE_WHITE_PAPER: 'ContentfulPageWhitePaper',
  TOPIC_EVENT_TYPE: 'ContentfulTopicEventType',
  TOPIC_WEBINAR_TYPE: 'ContentfulTopicWebinarType',
}

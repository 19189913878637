import React, { useEffect, useState } from 'react'

// Styles
import { StyledAnnouncement } from './announcement'

// Helpers
import { returnSelectedLocaleData } from '../../../utils/functions.utils'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'

// Data
import announcementStaticQuery from './announcementStaticQuery'

// Components
import Typography from '../typography'
import WrapperImage from '../wrapperImage'

type AnnouncementProps = {
  locale: string
  page?: string
}

export interface WrapperAssetResult {
  altText: string
  id: string
  image: {
    file: {
      url: string
    }
  }
}

export interface AnnouncementResult {
  entryLocale: string
  id: string
  name: string
  node_locale: string
  placement: string
  publishDate: string
  text: any
  unpublishDate: string
  useCase: string
  icon: WrapperAssetResult
}

const Announcement = (props: AnnouncementProps) => {
  const [contentfulAnnouncement, setContentfulAnnouncement] =
    useState<AnnouncementResult | null>(null)
  let dynamicData = announcementStaticQuery.allContentfulComponentAnnouncement()

  const renderAppropriateBanner = () => {
    if (
      dynamicData &&
      dynamicData.allContentfulComponentAnnouncement &&
      dynamicData.allContentfulComponentAnnouncement.edges &&
      Array.isArray(dynamicData.allContentfulComponentAnnouncement.edges)
    ) {
      if (props.locale) {
        const announcementToRender = returnSelectedLocaleData(
          dynamicData.allContentfulComponentAnnouncement.edges,
          props.locale
        )
        // If the unpublish date is in the past it shouldn't be shown
        // If the publish date is later than today it shouldn't be shown
        const dontShowBanner =
          new Date(announcementToRender.unpublishDate) > new Date() ||
          new Date() < new Date(announcementToRender.publishDate)

        if (
          announcementToRender &&
          Array.isArray(announcementToRender) &&
          announcementToRender.length !== 0 &&
          announcementToRender[0] &&
          announcementToRender[0].node &&
          !dontShowBanner
        ) {
          if (typeof window !== `undefined`) {
            setContentfulAnnouncement(announcementToRender[0].node)
          } else {
            return announcementToRender[0].node
          }
        }
      }
    }
  }

  useEffect(() => {
    renderAppropriateBanner()
  }, [props.page])

  const dynamicAnnouncement = () => {
    let announcementData
    if (typeof window == `undefined`) {
      announcementData = renderAppropriateBanner()
    } else {
      announcementData = contentfulAnnouncement
    }
    if (announcementData) {
      if (
        announcementData.useCase &&
        announcementData.useCase &&
        props.page &&
        announcementData.useCase === props.page
      ) {
        const iconSrc = announcementData?.icon?.image?.file?.url
          ? announcementData.icon.image.file.url
          : ''
        const iconAlt = announcementData?.icon?.altText
          ? announcementData.icon.altText
          : ''

        const text = announcementData?.text ? announcementData.text : null

        return (
          <StyledAnnouncement data-cy="announcement">
            <div className="Announcement__Inner">
              {iconSrc && iconAlt && (
                <div className="Announcement__Inner-Icon">
                  <WrapperImage
                    src={iconSrc}
                    alt={iconAlt}
                    height={16}
                    width={16}
                    threshold={650}
                  />
                </div>
              )}
              <div className="Announcement__Inner-Text">
                <Typography type="bodyXS">
                  {text && renderRichText(text, options)}
                </Typography>
              </div>
            </div>
          </StyledAnnouncement>
        )
      } else {
        return <div></div>
      }
    } else {
      return <div></div>
    }
  }

  if (dynamicData) {
    return dynamicAnnouncement()
  } else {
    return <div></div>
  }
}

export default Announcement

import { useStaticQuery, graphql } from 'gatsby'

const paginationMicrocopy = () => {
  return useStaticQuery(graphql`
    {
      allContentfulMicrocopySet(
        filter: {key: {regex: "/ResourceListing/i"}, node_locale: {eq: "en-US"}}
      ) {
        edges {
          node {
            key
            values {
              key
              value
            }
          }
        }
      }
    }
  `)
}

export default { paginationMicrocopy }

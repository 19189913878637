import React from 'react'
import {
  Header4,
  Header5,
  Header6,
  Body,
  BodyXS,
  CaptionAlt,
  Label,
  Caption,
} from '../typography/typography'
import Button from '../button'
import CheckCircle from '../../../../static/steam-img/icons/Check_CircleFill.svg'
import SVGCheck from '../../../../static/steam-img/icons/24x24_Check_Circle.svg'
import WrapperImage from '../wrapperImage'


const PlainText = (props: any) => (
  <p dangerouslySetInnerHTML={{ __html: props.text }} />
)

const textMarkup = (type: any, text: any, className?: any) => {
  if (type === 'Header4')
    return <Header4 {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (type === 'Header5')
    return <Header5 {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (type === 'Header6')
    return <Header6 {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (type === 'BodyXS')
    return <BodyXS {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (type === 'Body')
    return <Body {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (type === 'CaptionAlt')
    return (
      <CaptionAlt {...className} dangerouslySetInnerHTML={{ __html: text }} />
    )
  else if (type === 'Caption')
    return <Caption {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (type === 'Label')
    return <Label {...className} dangerouslySetInnerHTML={{ __html: text }} />
  else if (className) return <PlainText {...className} text={text} />
  else return text
}

const parser = (data: any, templateType?: string) => {
  let innerClassName = data.innerClassName
    ? { className: data.innerClassName }
    : {}

  let value = ''
  switch (data.type) {
    case 'text':
      value = data.value || ''
      return textMarkup(data.textType, value, innerClassName)
    case 'button':
      value = data.value || ''
      let buttonType = data.buttonType
      let buttonLink = data.link || ''
      return ['primary', 'secondary', 'tertiary', 'text-link'].includes(
        buttonType.toLowerCase()
      ) ? (
        <Button
          icon={true}
          href={buttonLink}
          text={value}
          type={buttonType}
          className={`Table__CTA--${buttonType.toLowerCase()}`}
          size="small"
          theme="light"
        />
      ) : (
        ''
      )
    case 'check':
      return templateType === 'TemplateTwo' ? (
        <WrapperImage src={CheckCircle} threshold={650} />
      ) : (
        <WrapperImage src={SVGCheck} threshold={650} />
      )
  }
}

/**
 * Creates td/th element based on data
 * @param data Object
 * @param templateType table template
 * @param rowNumber if first row number use <th> else <td>
 * @param children additional child elements added via code
 * @param extraAttributes HTML attributes
 * @returns
 */
const getMarkup = (
  data: any,
  templateType: string,
  rowNumber: number,
  children?: any,
  extraAttributes?: any
) => {
  let markUp = []
  if (data) {
    if (data.type === 'MultiField' && Array.isArray(data.values)) {
      for (let i = 0; i < data.values.length; i++) {
        markUp.push(parser(data.values[i], templateType))
      }
    } else {
      markUp.push(parser(data, templateType))
    }
    let attributes = {}

    // Merge classNames if exist in JSON and extraAttributes
    if (extraAttributes && extraAttributes.className && data.outerClassName) {
      extraAttributes.className += ` ${data.outerClassName}`
      attributes = { ...extraAttributes }
    } else
      attributes = data.outerClassName
        ? { className: data.outerClassName, ...extraAttributes }
        : extraAttributes

    let toolTipText: any = ''
    // Only add tooltip for cells with corresponding className and of templateType
    if (
      data.toolTipText &&
      data.outerClassName === 'Table__Topic' &&
      templateType === 'TemplateTwo'
    ) {
      toolTipText = (
        <BodyXS
          className="Table__ToolTip"
          dangerouslySetInnerHTML={{ __html: data.toolTipText }}
        />
      )
    }

    return rowNumber === 1 ? (
      <th {...attributes}>
        {markUp}
        {children}
        {toolTipText}
      </th>
    ) : (
      <td {...attributes}>
        {markUp}
        {children}
        {toolTipText}
      </td>
    )
  } else return null
}

export default getMarkup

import styled from 'styled-components'

export const HeaderContainer = styled.div`
  height: 64px;

  .MinHeader {
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: #fff;
  }
  .MinHeader-overlay {
    position: fixed;
    display: block;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .MinHeader-logo {
    padding: 16px 16px;
  }
  .MinHeader-searchBtn {
    font-size: 16px;
    color: rgb(39, 49, 57);
    font-weight: 600;
  }
  .MinHeader-menu.opened {
    visibility: visible;
  }
  .MinHeader-menuBtn > svg {
    visibility: visible;
    position: absolute;
    top: 16px;
    z-index: 9;
    right: 16px;
    width: 30px;
    height: 30px;
  }
  .MinHeader-menuBtn-text:before {
    visibility: visible;
    position: absolute;
    top: 22px;
    z-index: 9;
    right: 52px;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: #000000;
    content: 'MENU';
    ${(p) => p.theme.breakpoints.down('sm')} {
      content: '';
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      content: '';
    }
  }
  .opened .MinHeader-menuBtn-text:before {
    content: 'CLOSE';
    font-weight: 600;
    font-size: 12px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      content: '';
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      content: '';
    }
  }
  .MinHeader-menu {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    padding: 16px 25px;
    width: 350px;
    position: absolute;
    right: 0px;
    top: 0px;
    background: #ffffff;
    height: 100vh;
    overflow: auto;
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 240px;
      padding: 8px 25px;
    }
  }
  .MinHeader-MenuItem {
    font-size: 16px;
    display: block;
    width: 100%;
    text-align: left;
    margin: 32px 0;
  }
  .MinHeader-MenuItem:last-child {
    margin-bottom: 16px;
  }
  .MinHeader-MenuItemBtn-Container {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }
  .MinHeader-MenuItemBtn {
    text-align: left;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #273139;
  }
  .open .MinHeader-MenuItemBtn {
    color: #0067df;
    margin-bottom: 16px;
  }
  .MinHeader-SubMenu {
    display: none;
    width: 340px;
    background: #f7f7f7;
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 240px;
    }
  }
  .MinHeader-SubMenuItem {
    font-size: 14px;
    line-height: 24px;
    padding: 16px 32px;
    color: #000000;
  }
  .MinHeader-SubMenuItem:hover {
    background: #0067df;
    color: #ffffff;
  }
  .MinHeader-menuContainer {
    margin: 16px 0;
  }
  .MinHeader-CTA {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 32px 0;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px 0;
    }
  }
  .MinHeader-CTA:after {
    content: '→';
    margin-left: 16.67px;
  }
  .MinHeader-topBar-links {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #273139;
  }
  .MinHeader-contactButton {
    font-size: 14px;
    line-height: 24px;
    color: rgb(39, 49, 57);
    padding: 0 0px 14px 0;
  }
  .MinHeader-MenuItemBtn:after {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    float: right;
    content: '';
  }
  .open .MinHeader-MenuItemBtn:after {
    border-color: rgb(211, 212, 214);
    transform: rotate(-45deg) scaleY(-1);
    -webkit-transform: rotate(-45deg) scaleY(-1);
    -moz-transform: rotate(-45deg) scaleY(-1);
    transition: transform 0.2s ease 0s, -webkit-transform 0.2s ease 0s;
  }
  .headerSearch {
    margin-bottom: 16px;
  }
  .MinHeader-btn--languageSwitcher {
    color: rgb(39, 49, 57);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .MinHeader-btn--languageSwitcher:after {
    width: 0px;
    height: 0px;
    transform: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgb(39, 49, 57);
    border-bottom: none;
    content: '';
    position: absolute;
    left: 65px;
    top: 8px;
  }
  .MinHeader-menuContainer--languageSwitcher {
    position: relative;
    padding-bottom: 16px;
  }
  .MinHeader-deepList--languageSwitcher {
    display: none;
    background-color: rgb(247, 247, 247);
  }
  .MinHeader-deepList--languageSwitcher.is-active {
    margin-bottom: 32px;
  }
  .MinHeader-listItem--languageSwitcher {
    padding: 10px 10px 9px 24px;
  }
  .MinHeader-listItem--languageSwitcher:hover {
    background: #0067df;
  }
  .MinHeader-listItem--languageSwitcher:hover > a {
    color: #fff;
  }
  .MinHeader-link--languageSwitcher {
    font-size: 14px;
    color: #000000;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }
  .is-active {
    display: grid;
    margin-bottom: 16px;
    color: #0067df;
  }
  // Hamburger animation
  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
`

import React from 'react'
import Container from '@material-ui/core/Container'
import ImgUtil from '../../../utils/img.utils'
import { ImageContainer } from './image'
import DottedModal from '../dottedModal'
import WrapperImage from '../wrapperImage'

const Image = (props: any) => {
  if (
    props.data.imageFile &&
    props.data.imageFile.file &&
    props.data.imageFile.file.url
  ) {
    let alt = props.data.imageFile.title ? props.data.imageFile.title : ''
    let alignmentClass = props.data.imageSelfAlignment
      ? `Align--${props.data.imageSelfAlignment}`
      : ''
    const outerPage = props.outerPage ? props.outerPage : 'generic'

    let enlargeable = props.enlargeable
    let dottedModal = null
    let imageContainerProps = {}

    if (enlargeable) {
      const [open, setOpen] = React.useState(false)

      const handleClose = () => {
        setOpen(false)
      }
      const handleOpen = (e: any) => {
        setOpen(true)
      }
      dottedModal = (
        <DottedModal open={open} handleClose={handleClose} type="image">
          <WrapperImage
            src={ImgUtil.getResizedImageUrl(props.data.imageFile.file.url, '')}
            srcSet={ImgUtil.getSrcSet(props.data.imageFile.file.url)}
            sizes={'50vw'}
            loading="lazy"
            alt={alt}
            threshold={650}
          />
        </DottedModal>
      )
      imageContainerProps = {
        enlargeable: enlargeable,
        onClick: handleOpen,
      }
    }

    return (
      <>
        {dottedModal}
        <ImageContainer
          {...imageContainerProps}
          data-cy="Image-Container"
          $outerPage={outerPage}
        >
          {enlargeable && (
            <svg
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="search-icon"
                clipRule="evenodd"
                d="m10.7414 10.464 3.8023 3.8022c.262.2613.262.6861 0 .9474-.2613.262-.6861.262-.9474 0l-3.80224-3.8022c-1.0318.8254-2.34031 1.3185-3.76406 1.3185-3.33057 0-6.03-2.6994-6.03-6.02998 0-3.33057 2.69943-6.029998 6.03-6.029998s6.03 2.699428 6.03 6.029998c0 1.42375-.4931 2.73226-1.3186 3.76408zm-5.3814-4.43408v-1.675c0-.36984.30016-.67.67-.67s.67.30016.67.67v1.675h1.675c.36984 0 .67.30016.67.67s-.30016.67-.67.67h-1.675v1.675c0 .36984-.30016.67-.67.67s-.67-.30016-.67-.67v-1.675h-1.675c-.36984 0-.67-.30016-.67-.67s.30016-.67.67-.67zm.67 5.35998c2.59022 0 4.69-2.09976 4.69-4.68998s-2.09978-4.69-4.69-4.69-4.69 2.09978-4.69 4.69 2.09978 4.68998 4.69 4.68998z"
                fillRule="evenodd"
              />
            </svg>
          )}
          <Container className={alignmentClass} data-cy="Image">
            <WrapperImage
              loading="lazy"
              alt={alt}
              className="Image__Element"
              src={ImgUtil.getResizedImageUrl(
                props.data.imageFile.file.url,
                ''
              )}
              threshold={650}
            />
          </Container>
        </ImageContainer>
      </>
    )
  }

  return null
}

export default Image

// Libraries
import React, { useEffect, useState } from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { ThemeProvider } from 'styled-components'

// Images
import favicon from '../../../static/steam-img/favicon.ico'

// Components
import Helmet from 'react-helmet'
import Footer from '../global/footer'
import Header from '../global/header'
import MinHeader from '../global/minHeader'
import Announcement from '../global/announcement'
import LocalizedBanner from '../global/localizedBanner'
// Styles
import { GlobalStyle } from '../../styles/global'
import palette from '../../styles/palette'
import typography from '../../styles/typography'

const customizedTheme = createTheme({
  webMktPalette: palette,
  webMktTypography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 1025,
      lg: 1281,
      xl: 1600,
    },
  },
})

class Template extends React.Component {
  // componentDidMount() {
  //   const script = document.createElement('script')
  //   script.src = '/steam-data/des_carry_v_111_min.js'
  //   script.async = true
  //   document.body.appendChild(script)
  // }

  render() {
    const { children, lang, hideNormalLayout, minimalHeader, minimalFooter } =
      this.props
    const pageType = this.props.pageType ? this.props.pageType : ''

    return (
      <div data-cy="Layout" lang={lang}>
        <MuiThemeProvider theme={customizedTheme}>
          <ThemeProvider theme={customizedTheme}>
            <GlobalStyle />
            <Helmet>
              <link rel="icon" href={favicon} />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
              />
              <link
                rel="preconnect"
                href="https://hubspot-cdn.uipath.com"
                crossOrigin
              />
              <link
                rel="preconnect"
                href="https://www.googletagmanager.com"
                crossOrigin
              />
            </Helmet>
            {/* <LocalizedBanner
              slug={currentSlug}
              defaultBrowserLanguage={browserLanguage}
            /> */}
            <Announcement locale={lang} page={pageType} />
            {!hideNormalLayout && !minimalHeader && <Header locale={lang} />}
            {!hideNormalLayout && minimalHeader && <MinHeader />}
            {children}
            {!hideNormalLayout && (
              <Footer minimalFooter={minimalFooter} locale={lang} />
            )}
          </ThemeProvider>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default { Template }

// const Template = (props: any) => {
//   const {
//     children,
//     lang,
//     hideNormalLayout,
//     minimalHeader,
//     minimalFooter,
//     slug,
//     pageType,
//   } = props

//   const [browserLanguage, setBrowserLanguage] = useState('')
//   const [currentSlug, setCurrentSlug] = useState(slug)

//   useEffect(() => {
//     const script = document.createElement('script')
//     script.src = '/steam-data/des_carry_v_111_min.js'
//     script.async = true
//     document.body.appendChild(script)

//     if (typeof navigator === 'object') {
//       const browserLanguage =
//       (navigator.languages && navigator.languages[0]) || navigator.language
//       setBrowserLanguage(browserLanguage)
//     }
//   }, [])

//   useEffect(() => {
//     setCurrentSlug(slug)
//   }, [slug, setCurrentSlug])

//   return (
//     <div data-cy="Layout" lang={lang}>
//       <MuiThemeProvider theme={customizedTheme}>
//         <ThemeProvider theme={customizedTheme}>
//           <GlobalStyle />
//           <Helmet>
//             <link rel="icon" href={favicon} />
//             <link
//               rel="preconnect"
//               href="https://fonts.gstatic.com"
//               crossOrigin
//             />
//             <link
//               rel="preconnect"
//               href="https://hubspot-cdn.uipath.com"
//               crossOrigin
//             />
//             <link
//               rel="preconnect"
//               href="https://www.googletagmanager.com"
//               crossOrigin
//             />
//           </Helmet>
//           <LocalizedBanner
//             slug={currentSlug}
//             defaultBrowserLanguage={browserLanguage}
//           />
//           <Announcement locale={lang} page={pageType} />
//           {!hideNormalLayout && !minimalHeader && <Header locale={lang} />}
//           {!hideNormalLayout && minimalHeader && <MinHeader />}
//           {children}
//           {!hideNormalLayout && (
//             <Footer minimalFooter={minimalFooter} locale={lang} />
//           )}
//         </ThemeProvider>
//       </MuiThemeProvider>
//     </div>
//   )
// }

// export default { Template }

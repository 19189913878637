import { LocaleCodes } from '../constants/localization.constants'

export const getMicrocopyData = (
  microcopyset: any,
  number: number,
  ctaType: string,
  microcopyParameter: string
) => {
  let results = []

  if (microcopyset.values && Array.isArray(microcopyset.values)) {
    results = microcopyset.values.filter((microcopy: any) => {
      if (microcopy.key && typeof microcopy.key === 'string') {
        let items = microcopy.key.split('.')
        return (
          items.length &&
          items.length >= 2 &&
          (items[1] == number || items[2] == number) &&
          items.includes(`${ctaType}CTA`) &&
          items.includes(microcopyParameter)
        )
      }
      return false
    })
  }
  return results.length ? results : [{ value: 'Read more' }]
}

export const getMicrocopyWithoutNumber = (
  microcopyset: any,
  microcopyType: string,
  microcopyParameter: string
) => {
  let results
  if (
    microcopyset &&
    microcopyset.values &&
    Array.isArray(microcopyset.values)
  ) {
    results = microcopyset.values.filter((microcopy: any) => {
      if (microcopy.key && typeof microcopy.key === 'string') {
        return (
          microcopy.key.includes(microcopyType) &&
          microcopy.key.includes(microcopyParameter)
        )
      }
      return false
    })
  }
  if (results) {
    return results.length ? results : [{ value: '' }]
  }
}

// find {key, value} in microcopy/object
export const findTarget = (
  target: string,
  generics: { key: string; value: any }[],
  exactMatch: boolean = true
) => {
  const found = generics.find((generic: { key: string }) =>
    exactMatch ? generic.key === target : generic.key.includes(target)
  )
  return found ? found.value : ''
}

export const matchLocaleToMicrocopy = (
  microcopySet: any,
  microcopySetName: string,
  pageLocale: string
) => {
  let localeMicroSet, englishSet: any
  localeMicroSet = microcopySet.filter((microSet: any) => {
    if (
      microSet.node &&
      microSet.node.key &&
      microSet.node.key.includes(microcopySetName)
    ) {
      const localeOfSet = microSet.node.key.split(`${microcopySetName}-`)
      if (localeOfSet && localeOfSet[1]) {
        if (localeOfSet[1] === LocaleCodes.ENGLISH.code) {
          englishSet = microSet
        }
        return localeOfSet[1] === pageLocale
      }
    }
    return false
  })

  if (localeMicroSet && localeMicroSet[0] && localeMicroSet[0]?.node) {
    return localeMicroSet[0].node
  } else if (englishSet && englishSet.node) {
    // Default to English if there's no microcopy set for the locale
    return englishSet.node
  }
}

import styled from 'styled-components'

export const StyledAnnouncement = styled.div`
  width: 100%;
  background: ${(p) => p.theme.webMktPalette.background.gray};
  padding: 4px 0;
  box-sizing: border-box;
  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 4px 16px;
  }
  .Inline-CTA-Container {
    display: inline;
    margin-left: 8px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  }
  .Announcement__Inner {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .Announcement__Inner-Text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      ${(p) => p.theme.breakpoints.down('sm')} {
        text-align: left;
      }
    }
    .Announcement__Inner-Icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      display: flex;
      margin-right: 8px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`

import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  ${({ paginationTheme, theme }) => {
    let accentColor,
      activeColor,
      backgroundColor,
      textColor,
      hoverColor,
      inactiveBorderColor,
      paginationButtonStyles = ''

    if (paginationTheme === 'dark') {
      backgroundColor = theme.webMktPalette.background.dark
      accentColor = inactiveBorderColor = theme.webMktPalette.text.secondary
      textColor = theme.webMktPalette.text.primaryWhite
      activeColor = theme.webMktPalette.background.white
      hoverColor = theme.webMktPalette.text.actionDark
    } else if (paginationTheme === 'light') {
      backgroundColor = theme.webMktPalette.background.white
      accentColor = theme.webMktPalette.text.secondary
      inactiveBorderColor = theme.webMktPalette.grayNeutral[25]
      textColor = theme.webMktPalette.text.primaryBlack
      activeColor = theme.webMktPalette.background.black
      hoverColor = theme.webMktPalette.text.actionLight
    }

    const classesForButtons = [
      'Pagination__Numbered-Button',
      'Pagination__Previous-Button',
      'Pagination__Next-Button',
    ]

    const generateStyles = (className) => {
      return `
      .${className} {
        color: ${textColor};
        border: 2px solid ${inactiveBorderColor};
        background-color: ${backgroundColor};
        .Pagination__Button-Text {
          color: ${textColor};
        }
        &:hover {
          background-color: ${hoverColor};
          color: ${theme.webMktPalette.text.primaryWhite};
          border: 2px solid ${hoverColor};
          p {
            color: ${theme.webMktPalette.text.primaryWhite};
          }
        }
    
        &--Active {
          background-color: ${backgroundColor};
          color: ${activeColor};
          border: 2px solid ${activeColor};
        }
      }
    `
    }

    for (let i = 0; i < classesForButtons.length; i++) {
      paginationButtonStyles += generateStyles(classesForButtons[i])
    }

    return `
    .Pagination__Inner-Container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .Pagination__Numbered-Button {
      width: 48px;
      ${theme.breakpoints.down('xs')} {
        &:not(.Pagination__Numbered-Button--Active) {
          display: none;
        }
      }
    }
    .Pagination__Numbered-Button,
    .Pagination__Previous-Button,
    .Pagination__Next-Button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      padding: 12px 16px;
      box-sizing: border-box;
      text-align: center;
      transition: all 0.2s ease-in-out;
    }
    ${paginationButtonStyles}

    .Pagination__Ellipses {
      color: ${accentColor};
      margin: 0;
      min-width: 42px;
      width: 42px;
      box-sizing: border-box;
      padding: 12px 16px;
      text-align: center;
      transition: all 0.2s ease-in-out;
      background-color: none;
      ${theme.breakpoints.down('xs')} {
        display: none;
      }
    }
    .Pagination__Previous-Button {
      .Pagination__Mobile-Hide {
        margin-left: 8px;
      }
    }
  
    .Pagination__Next-Button {
      .Pagination__Mobile-Hide {
        margin-right: 8px;
      }
    }
  `
  }}
`

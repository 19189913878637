import React, { useState, useEffect } from 'react'

// Microcopy data
import paginationStaticQuery from './paginationStaticQuery'

// Styles
import { PaginationWrapper } from './pagination'

// Components
import Typography from '../typography'

// Helpers
import {
  getMicrocopyWithoutNumber,
  matchLocaleToMicrocopy,
} from '../../../utils/microcopy.utils'

type PaginationProps = {
  activePage: number
  handleClick: (val: number) => void
  numPagesBeforeEllipses: number
  numPages?: number
  numResultsPerPage?: number
  numResults?: number
  className?: string
  showPageNumbers?: boolean
  customNextText?: string
  customPreviousText?: string
  hrefForPage?: Function | null
  locale?: string | null
  theme?: string | null
}

/**
 * Takes in total number of pages to display
 * or uses total number of elements and divide by
 * number of elements to display per page.
 * Also takes in number of pages to display preceding the ellipses.
 *
 */
const Pagination = ({
  activePage,
  handleClick,
  numPages,
  numPagesBeforeEllipses = 5,
  numResults,
  numResultsPerPage,
  className,
  showPageNumbers = true,
  customNextText,
  customPreviousText,
  hrefForPage = null,
  locale,
  theme,
}: PaginationProps) => {
  const pageLocale = locale ? locale : 'en-US'
  const paginationTheme = theme ? theme : 'light'

  let dynamicData = paginationStaticQuery.paginationMicrocopy()
  let nextText = 'Next'
  let prevText = 'Previous'

  const getPaginationMicrocopy = () => {
    let nextPrevMicrocopy
    if (
      dynamicData &&
      dynamicData.allContentfulMicrocopySet &&
      dynamicData.allContentfulMicrocopySet.edges
    ) {
      nextPrevMicrocopy = matchLocaleToMicrocopy(
        dynamicData.allContentfulMicrocopySet.edges,
        'ResourceListing',
        pageLocale
      )
    }
    if (nextPrevMicrocopy) {
      const nextSet = getMicrocopyWithoutNumber(
        nextPrevMicrocopy,
        'NextPAGE',
        'text'
      )
      const prevSet = getMicrocopyWithoutNumber(
        nextPrevMicrocopy,
        'PreviousPAGE',
        'text'
      )
      if (nextSet && nextSet[0] && nextSet[0].value) {
        nextText = nextSet[0].value
      }
      if (prevSet && prevSet[0] && prevSet[0].value) {
        prevText = prevSet[0].value
      }
    }
  }

  const [isMobile, setIsMobile] = useState(false)
  const [renderingClientSide, setRenderingClientSide] = useState(false)
  // Get total pages
  let totalPages = 1
  if (numPages) {
    totalPages = numPages
  } else if (numResults && numResultsPerPage) {
    totalPages = Math.ceil(numResults / numResultsPerPage)
  }

  // Event Listener
  const handleButtonClick = (e: any, val: number) => {
    e.preventDefault()
    if (val < 1 || val > totalPages || val === activePage) {
      return
    }

    if (typeof handleClick === 'function') {
      handleClick(val)
    }
  }

  const handleIsMobile = () => {
    setIsMobile(window.innerWidth <= 768)
  }

  const paginationDisplay = (currentPage: number, pageCount: number) => {
    var current = currentPage,
      last = pageCount,
      delta = 1,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l

    range.push(1)
    for (let i = current - delta; i <= current + delta; i++) {
      if (i >= left && i < right && i < last && i > 1) {
        range.push(i)
      }
    }
    range.push(last)

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    return rangeWithDots
  }

  useEffect(() => {
    handleIsMobile()
    getPaginationMicrocopy()
    setRenderingClientSide(true)
  }, [])

  const generateItems = () => {
    const items = paginationDisplay(activePage, totalPages).map(
      (item: number | string, n: number) => {
        const classes =
          item === activePage
            ? 'Pagination__Numbered-Button Pagination__Numbered-Button--Active'
            : 'Pagination__Numbered-Button'

        const href = hrefForPage ? hrefForPage(item) : ''

        if (item === '...') {
          return (
            <Typography
              className="Pagination__Ellipses"
              type="bodyXS"
              key={`Ellipses-${n}`}
            >
              ...
            </Typography>
          )
        } else {
          return (
            <a
              key={`page-${n}-${href}`}
              href={href}
              className={classes}
              onClick={
                hrefForPage
                  ? undefined
                  : (e: any) => {
                      handleButtonClick(e, item)
                    }
              }
            >
              {item}
            </a>
          )
        }
      }
    )

    return items
  }

  const getButtonText = (direction: 'Next' | 'Prev') => {
    let textInner
    if (direction === 'Next') {
      if (customNextText) {
        textInner = customNextText
      } else {
        textInner = (
          <span>
            <span className="Pagination__Mobile-Hide">{nextText}</span>
            {`>`}
          </span>
        )
      }
    } else if (direction === 'Prev') {
      if (customPreviousText) {
        textInner = customPreviousText
      } else {
        textInner = (
          <span>
            {`<`}
            <span className="Pagination__Mobile-Hide">{prevText}</span>
          </span>
        )
      }
    }
    return (
      <Typography className="Pagination__Button-Text" type="bodyXS">
        {textInner}
      </Typography>
    )
  }

  const coreRender = () => {
    return (
      <PaginationWrapper
        paginationTheme={paginationTheme}
        className={className}
        data-cy="pagination"
      >
        <div className="Pagination__Inner-Container">
          {/* Don't show previous if on the first page */}
          {activePage !== 1 && (
            <a
              {...(hrefForPage ? { href: hrefForPage(activePage - 1) } : {})}
              className={`Pagination__Previous-Button`}
              onClick={
                hrefForPage
                  ? undefined
                  : (e: any) => {
                      handleButtonClick(e, activePage - 1)
                    }
              }
              rel="prev"
            >
              {/* if customPreviousText is set, the previous button text will not change, it will stay [< {customPreviousText}]' even on mobile. */}
              {getButtonText('Prev')}
            </a>
          )}

          {showPageNumbers && generateItems()}

          {/* Don't show next if on the last page */}
          {activePage !== totalPages && (
            <a
              {...(hrefForPage ? { href: hrefForPage(activePage + 1) } : {})}
              className={`Pagination__Next-Button`}
              onClick={
                hrefForPage
                  ? undefined
                  : (e: any) => {
                      handleButtonClick(e, activePage + 1)
                    }
              }
              rel="next"
            >
              {/* if customNextText is set, the next button text with not change, it will stay [{customNextText} >] even on mobile. */}
              {getButtonText('Next')}
            </a>
          )}
        </div>
      </PaginationWrapper>
    )
  }

  //we need to force this re-rendering when we're client side as the urls may have query parameters coming from window.location.search
  if (renderingClientSide) {
    return <div>{coreRender()}</div>
  } else {
    return coreRender()
  }
}

export default Pagination

import { useStaticQuery, graphql } from 'gatsby'

const allContentfulComponentAnnouncement = () => {
  return useStaticQuery(graphql`
    {
      allContentfulComponentAnnouncement {
        edges {
          node {
            entryLocale
            id
            name
            node_locale
            placement
            publishDate
            icon {
              ... on ContentfulWrapperAsset {
                id
                altText
                __typename
                image {
                  file {
                    url
                  }
                }
              }
            }
            text {
              raw
              references {
                ... on Node {
                  __typename
                  ... on ContentfulTopicCta {
                    text
                    contentful_id
                    id
                    name
                    externalUrl
                    internalLink {
                      __typename
                      ... on ContentfulPageProduct {
                        contentful_id
                        __typename
                        slug
                      }
                      ... on ContentfulPageFlex {
                        contentful_id
                        __typename
                        slug
                      }
                    }
                  }
                }
              }
            }
            unpublishDate
            useCase
          }
        }
      }
    }
  `)
}

export default { allContentfulComponentAnnouncement }

import styled from 'styled-components'
import videoButtonPlay from '../../../../static/steam-img/video-button-play.svg'

export const PlayButton = styled.div`
  cursor: pointer;
  :after {
    content: '';
    display: block;
    z-index: 2;
    position: absolute;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out;
    background: url(${videoButtonPlay}) center / 100% no-repeat;
  }
`

const StyledContainer = styled.section.attrs((props) => ({
  width: props.width || '100%',
  hasPadding: props.hasPadding || false,
}))`
  --container-padding: 20px;
  width: ${(props) => props.width}; // Falls back to 100%
  padding: ${(props) =>
    (props.hasPadding && 'var(--container-padding)') || 'none'};
`

export const VideoImageContainer = styled.div`
  ${({ $outerPage, theme }) => {
    if ($outerPage && $outerPage === 'aboutRPA') {
      return `
      background: ${theme.webMktPalette.background.gray};
    `
    }
  }}
`

export const VideoImage = styled.div`
  position: relative;
  z-index: 1;
  img {
    width: 100%;
  }
  ${({ $outerPage, theme }) => {
    if ($outerPage && $outerPage === 'aboutRPA') {
      return `
      width: 792px;
      height: 479px;
      overflow: hidden;
      margin: 0 auto;
      ${theme.breakpoints.down('md')} {
        width: 796px;
        height: 485px;
      }
      ${theme.breakpoints.down('sm')} {
        width: 536px;
        height: 387px;
      }
      ${theme.breakpoints.down('xs')} {
        width: 328px;
        height: 236px;
      }
      .VideoPlayer__Image-File {
        width: 100%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        position: absolute;
        left: 50%;
      }
    `
    }
  }}

  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0.4);
    }
    ${PlayButton}:after {
      opacity: 1;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    background: rgba(0, 0, 0, 0.2);
  }
`

export const VideoOverlay = styled.div`
  background-image: none;
  background-color: rgba(0, 0, 0, 0.6);
`

import { useStaticQuery, graphql } from 'gatsby'

const allContentfulNavMenu = () => {
  return useStaticQuery(graphql`
    {
      contentfulNavMenu(location: { eq: "Header" }) {
        name
        primaryCta {
          ... on ContentfulTopicCta {
            id
            text
            externalUrl
            internalLink {
              __typename
              ... on ContentfulPageProduct {
                id
                name
                slug
              }
              ... on ContentfulPageFlex {
                id
                name
                slug
              }
            }
          }
        }
        topHatLinks {
          ... on ContentfulTopicCta {
            id
            text
            externalUrl
            internalLink {
              __typename
              ... on ContentfulPageProduct {
                id
                name
                slug
              }
              ... on ContentfulPageFlex {
                id
                name
                slug
              }
            }
          }
        }
        menuGroups {
          ... on ContentfulMenuGroup {
            newGroupName
            parentLink {
              __typename
              ... on ContentfulTopicCta {
                id
                name
                description
                externalUrl
                internalLink {
                  __typename
                  ... on ContentfulPageProduct {
                    id
                    name
                    slug
                  }
                  ... on ContentfulPageFlex {
                    id
                    name
                    slug
                  }
                }
              }
            }
            pages {
              __typename
              ... on ContentfulTopicCta {
                text
                id
                name
                externalUrl
                description
                internalLink {
                  __typename
                  ... on ContentfulPageProduct {
                    id
                    name
                    slug
                  }
                  ... on ContentfulPageFlex {
                    id
                    name
                    slug
                  }
                }
              }
              ... on ContentfulMenuGroup {
                newGroupName
                parentLink {
                  __typename
                  ... on ContentfulTopicCta {
                    text
                    id
                    name
                    description
                    externalUrl
                    internalLink {
                      __typename
                      ... on ContentfulPageProduct {
                        id
                        name
                        slug
                      }
                      ... on ContentfulPageFlex {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
                pages {
                  __typename
                  ... on ContentfulTopicCta {
                    text
                    id
                    name
                    externalUrl
                    description
                    internalLink {
                      __typename
                      ... on ContentfulPageProduct {
                        id
                        name
                        slug
                      }
                      ... on ContentfulPageFlex {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default { allContentfulNavMenu }

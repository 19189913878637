export const LocaleCodes: any = {
  ENGLISH: {
    name: 'U.S. English',
    code: 'en-US',
  },
  FRENCH: {
    name: 'French',
    code: 'fr-FR',
  },
  GERMAN: {
    name: 'German',
    code: 'de-DE',
  },
  JAPANESE: {
    name: 'Japanese',
    code: 'ja-JP',
  },
  KOREAN: {
    name: 'Korean',
    code: 'ko-KR',
  },
  PORTUGUESE: {
    name: 'Portuguese',
    code: 'pt-BR',
  },
  SPANISH: {
    name: 'Spanish',
    code: 'es-MX',
  },
}

export const LanguageSelect: any = {
  rows: [
    {
      title: 'English',
      href: 'https://uipath.com/',
      lang: 'en',
      code: LocaleCodes.ENGLISH.code,
    },
    {
      title: 'Deutsch',
      href: 'https://uipath.com/de/',
      lang: 'de',
      code: LocaleCodes.GERMAN.code,
    },
    {
      title: 'Français',
      href: 'https://uipath.com/fr/',
      lang: 'fr',
      code: LocaleCodes.FRENCH.code,
    },
    {
      title: 'Portuguese',
      href: 'https://uipath.com/pt/',
      lang: 'pt',
      code: LocaleCodes.PORTUGUESE.code,
    },
    {
      title: 'Spanish',
      href: 'https://uipath.com/es/',
      lang: 'es',
      code: LocaleCodes.SPANISH.code,
    },
    {
      title: '简体中文',
      href: 'https://www.uipath.com.cn/',
      lang: 'cn',
      code: 'zh-cn',
    },
    {
      title: '日本語',
      href: 'https://uipath.com/ja/',
      lang: 'ja',
      code: LocaleCodes.JAPANESE.code,
    },
    {
      title: '대한민국',
      href: 'https://uipath.com/ko/',
      lang: 'ko',
      code: LocaleCodes.KOREAN.code,
    },
  ],
}

export const ResourceCategoryCodes: any = {
  'Video Demo': {
    6: 'en-US',
    126: 'ja-JP',
    146: 'ko-KR',
    26: 'de-DE',
    46: 'fr-FR',
    66: 'pt-BR',
    86: 'es-MX',
    106: 'cn-CN',
  },
  Events: {
    11: 'en-US',
    131: 'ja-JP',
    151: 'ko-KR',
    31: 'de-DE',
    51: 'fr-FR',
    71: 'pt-BR',
    91: 'es-MX',
    111: 'cn-CN',
  },
  'Case Study': {
    4: 'en-US',
    124: 'ja-JP',
    144: 'ko-KR',
    24: 'de-DE',
    44: 'fr-FR',
    64: 'pt-BR',
    84: 'es-MX',
    104: 'cn-CN',
  },
  Webinar: {
    7: 'en-US',
    127: 'ja-JP',
    147: 'ko-KR',
    27: 'de-DE',
    47: 'fr-FR',
    67: 'pt-BR',
    87: 'es-MX',
    107: 'cn-CN',
  },
}

export const LocaleCodesSlugPrefix: any = {
  'en-US': 'en',
  'fr-FR': 'fr',
  'de-DE': 'de',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'pt-BR': 'pt',
  'es-MX': 'es',
  'en': 'en',
  'fr': 'fr',
  'de': 'de',
  'ja': 'ja',
  'ko': 'ko',
  'pt': 'pt',
  'es': 'es',
}

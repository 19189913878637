import styled from 'styled-components'
import ImgUtil from '../../../utils/img.utils'
export const ContentRowsContainer = styled.div`
  ${(p) => {
    let paddingTopStyles,
      paddingBottomStyles,
      faqBodyTextColor,
      faqHeaderTextColor,
      faqBodyLinkColor,
      faqLineColor,
      faqArrow,
      backgroundStyles = ''

    if (p.topPadding === 'Large') {
      paddingTopStyles = 'padding-top: 96px;'
    } else if (p.topPadding === 'Small') {
      paddingTopStyles = 'padding-top: 64px;'
    } else if (p.topPadding === 'None') {
      paddingTopStyles = 'padding-top: 0;'
    }

    if (p.bottomPadding === 'Large') {
      paddingBottomStyles = 'padding-bottom: 96px;'
    } else if (p.bottomPadding === 'Small') {
      paddingBottomStyles = 'padding-bottom: 64px;'
    } else if (p.bottomPadding === 'None') {
      paddingBottomStyles = 'padding-bottom: 0;'
    }

    if (p.useCase === 'Agenda') {
      backgroundStyles = `background-color: unset;`
    } else if (p.backgroundTheme === 'Light') {
      backgroundStyles = `background-color: ${p.theme.webMktPalette.background.white};`
      faqHeaderTextColor = `color: ${p.theme.webMktPalette.text.primary};`
      faqLineColor = p.theme.webMktPalette.grayNeutral[35]
      faqBodyTextColor = `color: ${p.theme.webMktPalette.text.secondary};`
      faqArrow =
        'https://images.ctfassets.net/5965pury2lcm/5AWMlpmpSqveY4Ekn7oPyX/075fc0b80935bf76fe6bc707c3dd9d6d/Arrow_Up.svg'
      faqBodyLinkColor = p.theme.webMktPalette.text.actionLight
    } else if (p.backgroundTheme === 'Grey') {
      backgroundStyles = `background-color: ${p.theme.webMktPalette.background.gray};`
      faqHeaderTextColor = `color: ${p.theme.webMktPalette.text.primary};`
      faqLineColor = p.theme.webMktPalette.grayNeutral[35]
      faqBodyTextColor = `color: ${p.theme.webMktPalette.text.secondary};`
      faqArrow =
        'https://images.ctfassets.net/5965pury2lcm/5AWMlpmpSqveY4Ekn7oPyX/075fc0b80935bf76fe6bc707c3dd9d6d/Arrow_Up.svg'
      faqBodyLinkColor = p.theme.webMktPalette.text.actionLight
    } else if (p.backgroundTheme === 'Dark') {
      backgroundStyles = `background-color: ${p.theme.webMktPalette.background.dark};`
      faqHeaderTextColor = `color: ${p.theme.webMktPalette.text.primaryWhite};`
      faqLineColor = p.theme.webMktPalette.grayNeutral[60]
      faqBodyTextColor = `color: ${p.theme.webMktPalette.text.primaryWhite};`
      faqArrow =
        'https://images.ctfassets.net/5965pury2lcm/19t2yr8Go4Hb4kN8jVJgkg/1fbc99e8b4c5fe01180bc7c6801c321f/Arrow_Up-White.svg'
      faqBodyLinkColor = p.theme.webMktPalette.text.actionDark
    }

    return `
      ${paddingTopStyles}
      ${paddingBottomStyles}
      ${backgroundStyles}
      && {
        .FAQ__Item-Container {
          border-color: ${faqLineColor};
          .FAQ-Item__Label {
            ${faqHeaderTextColor}

            &:after {
              background-image: url(${ImgUtil.getResizedImageUrl(
                faqArrow,
                ''
              )});
            }
            &.FAQ-Item__Label:before {
              background-color: ${faqLineColor};
            }

          }
        }

        .FAQ-Item__Item-Body {
          .Inline-CTA-Container {
            width: 100%;
            text-align: center;
            margin: 24px 0;
          }
          ${faqBodyTextColor}
          p {
            ${faqBodyTextColor}
          }

        }
      }
    `
  }}
`

export const AgendaContainer = styled.div`
  margin-bottom: 48px;
  .Agenda__Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .Agenda__Item-Body {
      width: 100%;
      padding-left: 21.5%;
      margin: 16px 0 0 0;
    }
    .Agenda__Item-Label {
      width: 17%;
      margin: 0;
      color: ${(p) => p.theme.webMktPalette.grayCold[65]};
    }
    .Agenda__Item-Title {
      width: 78.5%;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
      .Agenda__Item-Label,
      .Agenda__Item-Title {
        width: 100%;
      }
      .Agenda__Item-Label {
        margin: 0 0 16px 0;
      }
      .Agenda__Item-Body {
        padding: 0;
      }
    }
  }
`

export const FAQContainer = styled.div`
  .FAQ__Item-Container {
    .FAQ-Item__Accordion-Item {
      display: none;
    }
    cursor: pointer;
    margin-bottom: 0;
    padding: 24px;
    border-bottom: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[35]};

    &:first-of-type {
      border-top: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[35]};
    }

    &:hover {
      background: none;
    }

    .FAQ-Item__Label {
      position: relative;
      padding-right: 141px;

      &:after {
        content: '';
        width: 24px;
        height: 24px;
        background-size: contain;
        top: 0px;
        right: 0;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(180deg);
      }

      ${(p) => p.theme.breakpoints.down('sm')} {
        padding-right: 87px;
      }

      ${(p) => p.theme.breakpoints.down('xs')} {
        padding-right: 49px;
      }
    }

    &.FAQ-Item__Active {
      .FAQ-Item__Label:after {
        transform: none;
      }

      .FAQ-Item__Label {
        padding-bottom: 24px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 172px;
          left: -16px;
          height: 1px;
        }
      }

      .FAQ-Item__Accordion-Item {
        margin-top: 24px;
        display: block;
        max-width: 768px;
      }
    }
  }
`

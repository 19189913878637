import styled from 'styled-components'
import GlobalContainer from '../globalContainer'

export const StyledGlobalContainer = styled(GlobalContainer)`
  ${(p) => {
    let paddingStyles = ''
    if (p.topPadding === 'Large') paddingStyles += 'padding-top: 96px;'
    else if (p.topPadding === 'Small') paddingStyles += 'padding-top: 64px;'
    if (p.bottomPadding === 'Large') paddingStyles += 'padding-bottom: 96px;'
    else if (p.bottomPadding === 'Small')
      paddingStyles += 'padding-bottom: 64px;'
    return paddingStyles
  }}

  padding-left: 0;
  padding-right: 0;
  overflow: hidden;

  tr td {
    &.divider,
    &.subDivider {
      width: 1200px;
      padding: 0;
      display: block;
    }
  }
`

//Used for Automation Cloud & Studio
export const TemplateOne = styled.div`
  background: ${(p) => p.theme.webMktPalette.background.gray};
  width: 100%;
  overflow: hidden;
  table {
    width: calc(100% + 48px);
    margin: 0 -24px;
    table-layout: fixed;
    text-align: left;
    border-spacing: 24px 0;
    position: relative;
    ${(p) => p.theme.breakpoints.down('sm')} {
      width: calc(100% + 32px);
      margin: 0 -16px;
      border-spacing: 16px 0;
    }
  }

  th {
    vertical-align: top;
  }

  th,
  td {
    padding: 24px 24px 24px 0;
  }

  th {
    &:nth-of-type(2) {
      border-bottom: 4px solid ${(p) => p.theme.webMktPalette.cyan[50]};
    }
    &:nth-of-type(3) {
      border-bottom: 4px solid ${(p) => p.theme.webMktPalette.blue[50]};
    }
    &:nth-of-type(4) {
      border-bottom: 4px solid ${(p) => p.theme.webMktPalette.orange[50]};
    }
  }

  .Table__Desktop {
    p {
      margin: 0;
    }
    .Table__Subheading {
      width: 1200px;
      display: block;
    }
  }

  .Table__Subheading.Table__Subheading {
    background: ${(p) => p.theme.webMktPalette.grayNeutral[20]};
    padding-left: 24px;
    p {
      color: ${(p) => p.theme.webMktPalette.text.primaryBlack};
    }
  }

  .Table__Topic {
    color: ${(p) => p.theme.webMktPalette.grayNeutral[55]};
    padding-left: 24px;
    p {
      color: inherit;
    }
  }

  td {
    position: relative;
  }

  tr td {
    &.divider {
      border-bottom: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[35]};
    }
    &.subDivider {
      border-bottom: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[25]};
    }
  }

  .Table__Slide {
    position: absolute;
    top: 24px;
    right: 0;
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 24px;
    z-index: 3;
  }

  .Table__Mobile {
    display: none;
    position: relative;
    margin: 0 auto;
    white-space: nowrap;
    overflow: scroll;
    padding-left: 16px;
    // height: 300px;

    .divider,
    .subDivider {
      display: table-cell;
    }
    p {
      margin: 0;
    }
    table {
      display: inline-table;
      max-width: 343px;
      white-space: normal;
      width: 100%;
      margin: 0 0 0 -16px;

      th {
        font-size: 20px;
        line-height: 24px;
        padding: 24px 58px 24px 24px;
        width: 311px;
        position: relative;
        box-sizing: border-box;
        position: sticky;
        top: 0;
        background: #f7f7f7;
        z-index: 2;

        &:nth-of-type(1) {
          border-bottom: 4px solid ${(p) => p.theme.webMktPalette.cyan[50]};
        }
        &:nth-of-type(2) {
          border-bottom: 4px solid ${(p) => p.theme.webMktPalette.blue[50]};
        }
        &:nth-of-type(3) {
          border-bottom: 4px solid ${(p) => p.theme.webMktPalette.orange[50]};
        }
        &:nth-of-type(4) {
          border-bottom: none;
        }
      }
    }

    .Table__Subheading {
      // position: sticky;
      // top: 76px;
      z-index: 1;
      border-top: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[35]};
      border-bottom: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[35]};
    }
  }

  @media screen and (max-width: 650px) {
    .Table__Desktop {
      display: none;
    }
    .Table__Mobile {
      display: block;
    }
  }
`

// Used for Pricing Table
export const TemplateTwo = styled.div`
  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }

  p {
    margin: 0;
  }

  table {
    width: 100%;
    text-align: left;
    table-layout: fixed;
    border-spacing: 0;
  }

  .Table__SubHeading.Table__SubHeading {
    padding: 24px 0 8px 0px;
    white-space: nowrap;
    p {
      color: ${(p) => p.theme.webMktPalette.text.secondary};
    }
  }

  th {
    padding: 16px 0px 16px 24px;
  }

  td {
    padding: 12px 0 12px 24px;
  }

  .Table__Topic {
    position: relative;
    cursor: pointer;
    &:hover {
      > :first-child {
        color: ${(p) => p.theme.webMktPalette.text.actionLight};
      }
      overflow: visible;
      .Table__ToolTip {
        display: block;
      }
    }
  }

  .Table__ToolTip {
    display: none;
    width: 384px;
    box-sizing: border-box;
    padding: 4px 16px;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    color: ${(p) => p.theme.webMktPalette.text.primaryWhite};
    background: ${(p) => p.theme.webMktPalette.background.dark};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 16px;
      width: 0;
      height: 0;
      transform: translateY(-100%);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 5px solid ${(p) => p.theme.webMktPalette.background.dark};
    }
  }

  .Table__CTA--text-link {
    margin-top: 8px;
    margin-bottom: 20px;
    padding-right: 32px;

    ${(p) => p.theme.breakpoints.down('md')} {
      margin-bottom: 36px;
    }
    span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
    }
    .CTA__Arrow-Container {
      margin-left: 8px;
      svg {
        width: 14px;
      }
    }
  }

  tr td {
    .Table__Text--Black.Table__Text--Black {
      color: ${(p) => p.theme.webMktPalette.text.primaryBlack};
    }

    // exclude left most column
    &:not(:first-of-type) {
      p {
        color: ${(p) => p.theme.webMktPalette.text.secondary};
      }
    }
    &.divider {
      border-bottom: 1px solid
        ${(p) => p.theme.webMktPalette.text.tertiaryLight};
    }
    &.subDivider {
      border-bottom: 1px solid ${(p) => p.theme.webMktPalette.grayNeutral[20]};
    }
  }

  tr {
    > th,
    > td {
      &:first-of-type:not(.divider):not(.subDivider) {
        width: 384px;
        ${(p) => p.theme.breakpoints.down('md')} {
          width: 304px;
        }
      }

      &:first-of-type:not(.Table__SubHeading):not(.divider):not(.subDivider) {
        padding: 8px 0;
      }

      &:not(:first-of-type):not(:last-of-type) {
        background: ${(p) => p.theme.webMktPalette.grayNeutral[5]};
      }
    }
  }
`

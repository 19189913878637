import Backdrop from '@material-ui/core/Backdrop'
import styled from 'styled-components'

export const ModalOuter = styled.div`
  border-radius: 2px;
  margin: 0 auto;
  position: absolute;
  top: 81px;
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 81px);

  .Dotted-Modal__Content-Container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 16px;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      box-sizing: border-box;

      pointer-events: none;
    }
  }
`

export const VideoModalInner = styled.div`
  transition: transform 0.35s ease 0s;
  transform: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  z-index: 2;

  iframe {
    width: 100%;
    height: 100%;
  }
`

export const CustomBackdrop = styled(Backdrop)`
  background-color: ${(p) => p.theme.webMktPalette.background.black} !important;
  opacity: 0.9 !important;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-size: 24px 24px;
    background-image: radial-gradient(white 1px, transparent 0);
  }
`

export const ModalHeader = styled.div`
  border-bottom: 1px solid #2d2e2f;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  background-color: #111212;

  img {
    height: 32px;
    width: auto;
    padding: 0 20px;
  }

  .Modal-Close-Icon {
    width: 24px;
    height: 24px;
  }

  > div {
    text-align: center;
    width: 81px;
    line-height: 81px;
    border-left: 1px solid #2d2e2f;
  }
`

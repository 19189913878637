// Constants
import { ModuleNames } from '../constants/componentNames.constants'
const internalOrigins = [
  'https://www.uipath.com',
  'https://uipath.com',
  'https://www.uipath-dev.com',
  'https://steam.uipath.com',
  'https://steam-dev.uipath.com',
  'https://staging.uipath-dev.com/',
  'http://localhost:8000',
]

export const isExternalURL = (url: string) => {
  // Always direct users to pdfs in a new tab
  if (url && url.includes('.pdf')) {
    return true
  }

  if (url && url.includes('mailto:')) {
    return false
  }

  try {
    const newUrl = new URL(url)
    const isTopLevelBrochuresLink =
      url && url.match(/^(.*?)\/resources\/automation-brochures\/?$/g)

    // All brochures and PDFs that don't end in .pdf except for the RC listing tab
    if (
      (url && url.includes('/resources/automation-brochures')) ||
      url.includes('/assets/downloads')
    ) {
      if (!isTopLevelBrochuresLink) {
        return true
      }
    }

    if (newUrl.origin) {
      if (internalOrigins.includes(newUrl.origin)) {
        return false
      } else {
        return true
      }
    }
  } catch (e) {
    if (url && url.charAt(0) === '/') {
      return false
    } else {
      return true
    }
  }
}
export const createLinkBasedOnType = (item: any) => {
  if (
    (item && item.__typename === ModuleNames.PAGE_PRODUCT) ||
    item.__typename === ModuleNames.PAGE_FLEX
  ) {
    return `/${item.slug}`
  } else if (item && item.__typename === ModuleNames.TOPIC_CTA) {
    if (item.externalUrl) {
      return `${item.externalUrl}`
    } else if (item.internalLink) {
      if (
        item.internalLink.__typename === ModuleNames.PAGE_PRODUCT ||
        item.internalLink.__typename === ModuleNames.PAGE_FLEX
      ) {
        if (item.internalLink.slug.charAt(0) === '/') {
          return item.internalLink.slug
        } else {
          return `/${item.internalLink.slug}`
        }
      }
    }
  }
}

export const createTextBasedOnType = (item: any) => {
  if (item && item.__typename === ModuleNames.TOPIC_CTA) {
    return item.text
  }
}

export type internalUrl = {
  slug?: string
  __typename: string
  blogCategory?: { seoMetadata: { pageCanonicalUrl: string } }
}

export const internalLinkHandler = (internalLink: internalUrl): string => {
  // set all slugs to begin with a '/' if it doesnt already begin with it...
  let slug =
    internalLink.slug && internalLink.slug[0] !== '/'
      ? `/${internalLink.slug}`
      : internalLink.slug

  switch (internalLink.__typename) {
    case 'ContentfulPageBlogPost':
      // Grabs the canonicalUrl => 'uipath.com/blog/rpa' -> ['uipath.com', '/blog/rpa'] -> '/blog/rpa'
      let canonicalUrl =
        internalLink.blogCategory?.seoMetadata.pageCanonicalUrl.split('.com')[1]
      return `${canonicalUrl}${slug}`
    case 'ContentfulPageMainBlog':
      return `/blog`
    default:
      return `${slug}`
  }
}

export const correctSlugPrefix = (slug: string): string => {
  if (slug[0] === '/') {
    return slug
  } else {
    return `/${slug}`
  }
}

export const generateThankYouPageURL = (
  genericURL: string,
  thankYouPage?: any
): string => {
  let thankYouPageUrl = ''

  if (thankYouPage) {
    if (
      thankYouPage?.__typename &&
      thankYouPage?.__typename === ModuleNames.PAGE_THANK_YOU
    ) {
      if (thankYouPage.slug) {
        thankYouPageUrl = thankYouPage.slug
      } else if (thankYouPage?.pageName) {
        thankYouPageUrl = `/g/thank-you-${thankYouPage.pageName
          .split(' ')
          .join('-')}`
      } else {
        thankYouPageUrl = genericURL
      }
    } else if (
      thankYouPage?.__typename &&
      thankYouPage?.__typename === ModuleNames.TOPIC_CTA
    ) {
      if (thankYouPage.externalUrl) {
        thankYouPageUrl = thankYouPage.externalUrl
      } else {
        thankYouPageUrl = genericURL
      }
    } else {
      thankYouPageUrl = genericURL
    }
  } else {
    thankYouPageUrl = genericURL
  }
  return thankYouPageUrl
}

import React from 'react'

// Components
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'

// Styles
import * as styles from './style'

// Images
import CloseIcon from '../../../../static/steam-img/Close-White.svg'
import Logo from '../../../../static/steam-img/UiPath-full-logo-white.svg'
import WrapperImage from '../wrapperImage'

type ModalProps = {
  open: boolean
  handleClose: any
  children: any
  type: string
}

export default function DottedModal(props: ModalProps) {
  const videoContainer = React.useRef(null)

  // primarily for asset `object-fit: contain` dimensions
  const getContentSize = (element: any) => {
    if (element.nodeName !== 'VIDEO' && element.nodeName !== 'IMG')
      return [element.offsetWidth, element.offsetHeight]
    const dimensions = {
      naturalHeight:
        element.nodeName === 'IMG'
          ? element.naturalHeight
          : element.videoHeight,
      naturalWidth:
        element.nodeName === 'IMG' ? element.naturalWidth : element.videoWidth,
      height: element.offsetHeight,
      width: element.offsetWidth,
    }

    var ratio = dimensions.naturalWidth / dimensions.naturalHeight

    var width = dimensions.height * ratio
    var height = dimensions.height
    if (width > dimensions.width) {
      width = dimensions.width
      height = dimensions.width / ratio
    }
    return [width, height]
  }

  const handleXClick = (e: any) => {
    e.preventDefault()
    let currentEl = e.target

    if (
      currentEl &&
      currentEl.classList.contains('Dotted-Modal__Content-Container')
    ) {
      // check click area
      if (currentEl.children && currentEl.children[0]) {
        var rect = currentEl.getBoundingClientRect()
        let containerWidth = rect.width
        let containerHeight = rect.height
        var x = e.clientX - rect.left // x position within the element
        var y = e.clientY - rect.top // y position within the element

        let child = currentEl.children[0]
        const [cWidth, cHeight] = getContentSize(child)

        /*
          offset for left/right and top/bottom edges,
          coordinates within this range is the content area
        */
        let offsetWidth = (containerWidth - cWidth) / 2
        let offsetHeight = (containerHeight - cHeight) / 2

        // check whether the click event happened inside the content area
        let cx = x > offsetWidth && x < containerWidth - offsetWidth
        let cy = y > offsetHeight && y < containerHeight - offsetHeight

        // click event happened inside content, do not close modal
        if (cx && cy) {
          return
        }
      }
    }

    // clicked occurred outside content area, exit modal
    props.handleClose()
  }

  const addEvents = (e: any) => {
    if (e) {
      window.addEventListener('resize', updateIframeDimensions)
      updateIframeDimensions()
    } else {
      window.removeEventListener('resize', updateIframeDimensions)
    }
  }

  const updateIframeDimensions = () => {
    let height
    let width
    if (videoContainer.current) {
      // 81px for modal header section
      const windowHeight = window.innerHeight - 81
      const windowWidth = window.innerWidth
      const windowAspect = windowHeight / windowWidth

      //add 32 px to width to accommodate for 16px margins
      const [dimensionWidth, dimensionHeight] = [1232, 693]
      const [dimensionWidthMobile, dimensionHeightMobile] = [768, 432]

      const iframeAspect = dimensionHeight / dimensionWidth
      const iframeAspectMobile = dimensionHeightMobile / dimensionWidthMobile

      let r = iframeAspect,
        h = dimensionHeight,
        w = dimensionWidth

      if (window.matchMedia('(max-width: 1280px)').matches) {
        r = iframeAspectMobile
        h = dimensionHeightMobile
        w = dimensionWidthMobile
      }

      /*
        When the window height is relatively smaller, iframe aspect would be greater.
        Minus 32 for 16px margins around.
      */
      if (r > windowAspect) {
        width = Math.min(h, windowHeight) / r - 32
        height = width * r
      } else {
        width = Math.min(w, windowWidth) - 32
        height = width * r
      }

      videoContainer.current.style.cssText += `width:${width}px;height:${height}px;`
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={handleXClick}
      closeAfterTransition
      ref={addEvents}
      BackdropComponent={styles.CustomBackdrop}
      BackdropProps={{
        timeout: 250,
      }}
      data-cy="DottedModal"
    >
      <Fade in={props.open}>
        <React.Fragment>
          <styles.ModalHeader>
            <WrapperImage src={Logo} />
            <div href="#" onClick={props.handleClose}>
              <WrapperImage
                src={CloseIcon}
                alt="Close Icon"
                className="Modal-Close-Icon"
                width={16}
                height={16}
                threshold={650}
              />
            </div>
          </styles.ModalHeader>
          <styles.ModalOuter
            type={props.type}
            data-cy="ModalOuter"
            onClick={handleXClick}
          >
            <div className="Dotted-Modal__Content-Container">
              {props.type === 'video' ? (
                <styles.VideoModalInner ref={videoContainer}>
                  {props.children}
                </styles.VideoModalInner>
              ) : (
                props.children
              )}
            </div>
          </styles.ModalOuter>
        </React.Fragment>
      </Fade>
    </Modal>
  )
}

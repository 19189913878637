const palette = {
  text: {
    primaryBlack: '#000000',
    primaryWhite: '#ffffff',
    secondary: '#595a5c',
    tertiaryLight: '#0000004d',
    tertiaryDark: '#ffffff4d',
    actionBlack: '#000000',
    actionWhite: '#ffffff',
    actionLight: '#0067df',
    actionOrange: '#fa4616',
    actionDark: '#38c6f4',
    error: '#f5222d',
    warning: '#faad14',
    success: '#52c41a',
  },
  background: {
    dark: '#1d1d1e',
    gray: '#f7f7f7',
    white: '#ffffff',
    black: '#000000',
  },
  icon: {
    white: '#ffffff',
    black: '#000000',
    blueLight: '#0067df',
    blueDark: '#38c6f4',
    black30: '#0000004d',
    white30: '#ffffff4d',
  },
  button: {
    primary: '#fa4616',
    secondaryBlack: '#000000',
    secondaryWhite: '#ffffff',
    ghostBlack: '#000000',
    ghostWhite: '#ffffff',
  },
  orange: {
    10: '#fee3dc',
    20: '#fda891',
    30: '#fc7753',
    40: '#fb5124',
    50: '#fa4616',
    60: '#db3c11',
    70: '#c2320c',
    80: '#832107',
    90: '#400f02',
  },
  blue: {
    10: '#dae8fa',
    20: '#9ac2f2',
    30: '#4e94e8',
    40: '#0c6edf',
    50: '#0067df',
    60: '#0061d3',
    70: '#00489d',
    80: '#00336f',
    90: '#001937',
  },
  grayNeutral: {
    5: '#fafafa',
    10: '#f7f7f7',
    15: '#f4f4f5',
    20: '#ecedee',
    25: '#e1e2e4',
    30: '#d3d4d6',
    35: '#c0c2c5',
    40: '#aaacaf',
    45: '#919295',
    50: '#757679',
    55: '#595a5c',
    60: '#414244',
    65: '#2d2e2f',
    70: '#1d1d1e',
    75: '#111212',
    80: '#0a0a0a',
    85: '#060606',
    90: '#050505',
  },
  grayCold: {
    5: '#f8fcfc',
    10: '#f4f9fa',
    15: '#f1f6f8',
    20: '#e9f0f3',
    25: '#dee6ea',
    30: '#cfd8dd',
    35: '#bbc7cd',
    40: '#a4b1b8',
    45: '#8a97a0',
    50: '#6e7b85',
    55: '#526069',
    60: '#3a464f',
    65: '#273139',
    70: '#182027',
    75: '#0e1419',
    80: '#080c10',
    85: '#04070a',
    90: '#030508',
  },
  pink: {
    10: '#fcdbe6',
    20: '#f7a1bd',
    30: '#f25a8c',
    40: '#ff266d',
    50: '#ed145b',
    60: '#d91153',
    70: '#a60e3f',
    80: '#76092d',
    90: '#3b0516',
  },
  yellow: {
    10: '#fff3db',
    20: '#ffe19e',
    30: '#ffca55',
    40: '#ffbb27',
    50: '#ffb40e',
    60: '#f3ab0d',
    70: '#b27d09',
    80: '#805a07',
    90: '#3f2c03',
  },
  purple: {
    10: '#eee1ee',
    20: '#d3aed3',
    30: '#b372b2',
    40: '#a244a1',
    50: '#933692',
    60: '#8c338b',
    70: '#662565',
    80: '#491b49',
    90: '#240d24',
  },
  cyan: {
    10: '#e1f6fd',
    20: '#afe8fa',
    30: '#73d6f7',
    40: '#44cffc',
    50: '#38c6f4',
    60: '#35bbe7',
    70: '#278aab',
    80: '#1c637a',
    90: '#0d303c',
  },
  green: {
    10: '#e0fae8',
    20: '#adf1c3',
    30: '#70e795',
    40: '#42e174',
    50: '#34de69',
    60: '#31d263',
    70: '#249b49',
    80: '#1a6f34',
    90: '#05361b',
  },
  error: {
    50: '#f5222d',
  },
}
export default palette

import styled from 'styled-components'

export const FooterWrapper = styled.div`
  .MainFooterRev {
    position: relative;
    background: url('https://www.uipath.com/hubfs/Valentin/images/footer/bg/footer-bg-v1.png')
      50% / cover no-repeat;
    width: 100%;
    height: 100%;
  }
  .MainFooterRev.MainFooterRev--rebranded {
    background: #111212;
  }

  ${(p) => {
    //mega menu styles
    if (!p.minimalFooter) {
      return `
        .MainFooterRev-column-name[id$="-parent"] {
          .footer-up-arrow {
            display: none;
          }
          &.is-active {
            .footer-down-arrow {
              display: none;
            }
            .footer-up-arrow {
              display: block;
            }
          }
        }
        .MainFooterRev .MainFooterRev-container {
          padding: 36px 0;
          display: -ms-flexbox;
          display: -webkit-box;
          display: flex;
          -ms-flex-direction: column;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;
          -ms-flex-pack: justify;
          -webkit-box-pack: justify;
          justify-content: space-between;
          background: #111212;
        }
        .MainFooterRev-column-name {
          padding: 10px 0;
        }
        .MainFooterRev .MainFooterRev-container .MainFooterRev-column {
          padding: 10px 0;
          margin: 0 20px;
          border-top: 1px solid rgba(185, 217, 235, 0.2);
        }
        .MainFooterRev .MainFooterRev-container .MainFooterRev-column:first-child {
          border-top: 0;
          border-bottom: 0;
        }
        .MainFooterRev .MainFooterRev-container .MainFooterRev-column:last-child {
          border-top: 1px solid rgba(185, 217, 235, 0.2);
          border-bottom: 1px solid rgba(185, 217, 235, 0.2);
        }
        .MainFooterRev-column span {
          color: #f5f8fa;
          text-transform: uppercase;
          font-family: Inter, sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 40px;
        }
        .MainFooterRev.MainFooterRev--rebranded .MainFooterRev-column span,
        .MainFooterRev.MainFooterRev--rebranded .MainFooterRev-column .MainFooterRev-parent-link {
          color: #cfd8dd;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 20px;
        }
        .MainFooterRev-parent-link:hover {
          text-decoration: underline;
        }
        .MainFooterRev .MainFooterRev-container .MainFooterRev-column img {
          float: right;
          margin-top: 10px;
          margin-right: 10px;
        }
        .MainFooterRev
          .MainFooterRev-container
          .MainFooterRev-column
          .MainFooterRev-deepList {
          border-top: 1px solid rgba(185, 217, 235, 0.2);
          padding-top: 20px;
          margin-top: 20px;
          display: none;
        }
        .MainFooterRev
          .MainFooterRev-container
          .MainFooterRev-column
          .MainFooterRev-deepList.is-active {
          display: -ms-flexbox;
          display: -webkit-box;
          display: flex;
          -ms-flex-direction: column;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;
          -ms-flex-pack: justify;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
        .MainFooterRev
          .MainFooterRev-container
          .MainFooterRev-column
          .MainFooterRev-deepList
          span {
          text-transform: none;
        }
        .MainFooterRev
          .MainFooterRev-container
          .MainFooterRev-column
          .MainFooterRev-deepList
          span
          a {
          display: inline-block;
          color: #f5f8fa;
          font-family: Inter, sans-serif;
          font-weight: 400;
          font-size: 16px;
          padding: 8px;
          margin: -8px;
          width: 100%;
        }
        .MainFooterRev
          .MainFooterRev-container
          .MainFooterRev-column
          .MainFooterRev-deepList
          span
          a:hover {
          text-decoration: underline;
        }
        @media screen and (min-width: 768px) {
          .MainFooterRev .MainFooterRev-container {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: row;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -ms-flex-pack: justify;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
          .MainFooterRev .MainFooterRev-container .MainFooterRev-column,
          .MainFooterRev .MainFooterRev-container .MainFooterRev-column:last-child {
            border-top: 0;
            border-bottom: 0;
          }
          .MainFooterRev .MainFooterRev-container .MainFooterRev-column img {
            display: none;
          }
          .MainFooterRev
            .MainFooterRev-container
            .MainFooterRev-column
            .MainFooterRev-deepList {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: column;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -ms-flex-pack: justify;
            -webkit-box-pack: justify;
            justify-content: space-between;
            padding-top: 0;
            margin-top: 8px;
            border-top: 0;
          }
          .MainFooterRev
            .MainFooterRev-container
            .MainFooterRev-column
            .MainFooterRev-deepList
            span {
            margin: 0;
          }
          .MainFooterRev
            .MainFooterRev-container
            .MainFooterRev-column
            .MainFooterRev-deepList
            span
            a {
            color: #cfd8dd;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;
          }
          .MainFooterRev.MainFooterRev--rebranded .MainFooterRev-column span {
            text-transform: none;
          }
        }
        @media screen and (min-width: 1024px) {
          .MainFooterRev .MainFooterRev-container {
            width: 100%;
          }
          .MainFooterRev
            .MainFooterRev-container
            .MainFooterRev-column
            .MainFooterRev-deepList
            span
            a {
            font-size: 14px;
            line-height: 32px;
            padding: 0;
            margin: 0;
          }
        }
        @media screen and (min-width: 1440px) {
          .MainFooterRev .MainFooterRev-container {
            width: 1200px;
          }
        }
      `
    }
  }}

  .MainFooterRev-minifooter {
    position: relative;
    width: 100%;
  }
  .MainFooterRev-minifooter--socialContainer {
    max-width: 1200px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
    border-top: 1px solid #1d1d1e;
  }

  @media screen and (min-width: 1024px) {
    .MainFooterRev-minifooter--socialContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      width: 100%;
    }
  }
  .MainFooterRev-minifooter--logo {
    display: inline-block;
    float: left;
  }
  @media screen and (min-width: 1024px) {
    .MainFooterRev-minifooter--logo {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 20%;
    }
  }
  .MainFooterRev-social-networks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
  }
  .MainFooterRev-social-networks a {
    display: block;
    width: 20px;
    margin: 0 18px;
  }
  .MainFooterRev-minifooter--bottomText {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px 15px 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .MainFooterRev-minifooter--legalLinks {
    display: block;
    text-align: center;
  }
  .MainFooterRev-minifooter--copyright {
    text-align: center;
  }
  .MainFooterRev-minifooter--copyright span,
  .MainFooterRev-minifooter--legalLinks span a {
    color: #526069;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin: 0 5px;
  }

  .MainFooterRev-minifooter--legalLinks span a.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    color: #526069 !important;
    font-family: Inter, sans-serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
    line-height: 16px !important;
    margin: 0 5px !important;
    padding: 0 !important;
  }
  .MainFooterRev-minifooter--legalLinks span {
    position: relative;
  }
  .MainFooterRev-minifooter--legalLinks > div {
    display: inline;
  }
  .MainFooterRev-minifooter--legalLinks > div > span:not(:first-child):before,
  .MainFooterRev-minifooter--legalLinks > span:before {
    content: '';
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #526069;
    border-radius: 50%;
    left: -2px;
    top: 11px;
  }
  .SecondaryFooter-languageSwitcher span {
    color: #cfd8dd;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
  }
  .SecondaryFooter-languageSwitcher img {
    float: right;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 2px;
  }
  .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList {
    position: absolute;
    top: -250px;
    width: 100%;
    display: none;
    background-color: #f5f8fa;
  }
  .SecondaryFooter
    .SecondaryFooter-container-mobile
    .SecondaryFooter-column
    .SecondaryFooter-languageSwitcher
    .SecondaryFooter-languageList.is-active {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 1;
  }
  .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList span {
    font-size: 16px;
    line-height: 32px;
    padding: 3px 0;
  }
  .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList span a {
    display: inline-block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 24px;
    color: #ffffff;
    text-decoration: none;
  }
  .SecondaryFooter-languageSwitcher
    .SecondaryFooter-languageList
    .footer-right-arrow {
    margin: 0;
    content: '';
    display: inline-block;
    position: absolute;
    width: 3px;
    height: 6px;
    border: solid #0067df;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 12px;
    right: 24px;
  }
  .MainFooterRev-social-networks svg {
    fill: #595a5c;
  }
  .SecondaryFooter
    .SecondaryFooter-container-desktop
    .SecondaryFooter-column
    .SecondaryFooter-languageSwitcher
    .SecondaryFooter-languageList.is-active
    a {
    color: #0085ca;
  }
  .SecondaryFooter
    .SecondaryFooter-container-desktop
    .SecondaryFooter-column
    .SecondaryFooter-languageSwitcher
    .SecondaryFooter-languageList.is-active
    a:hover {
    text-decoration: underline;
    color: #0085ca;
  }
  @media screen and (min-width: 768px) {
    .SecondaryFooter-languageSwitcher span {
      line-height: 24px;
    }
    .SecondaryFooter-languageSwitcher img {
      float: right;
      margin-top: 2px;
      margin-right: 10px;
      width: 12px;
      margin-bottom: 2px;
    }
    .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList {
      position: absolute;
      top: -150px;
      width: 100%;
      display: none;
      background-color: #f5f8fa;
      border: 1px solid #b9d9eb;
      border-radius: 4px;
    }
    .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList span {
      font-size: 11px;
      line-height: 24px;
    }
    .SecondaryFooter-languageSwitcher
      .SecondaryFooter-languageList
      .footer-right-arrow {
      margin: 0;
      display: none;
    }
  }
  .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList.is-active {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 1;
    width: 160px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: -230px;
    right: 20px;
    background: #1d1d1e;
    border: none;
  }
  @media screen and (min-width: 768px) {
    .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList.is-active {
      top: -180px;
    }
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList.is-active {
      top: -260px;
      left: -80px;
    }
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-languageSwitcher img {
      width: 14px;
    }
    .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList span {
      font-size: 14px;
      line-height: 32px;
    }
    .SecondaryFooter-languageSwitcher
      .SecondaryFooter-languageList.is-active
      a {
      color: #fff;
    }
    .SecondaryFooter-languageSwitcher
      .SecondaryFooter-languageList.is-active
      a:hover {
      color: #fff;
      background-color: #0067df;
    }
    .SecondaryFooter-languageSwitcher img {
      margin-top: 2px;
      margin-right: 10px;
      margin-bottom: 2px;
    }
    .SecondaryFooter-languageSwitcher .SecondaryFooter-languageList {
      top: -200px;
    }
    .MainFooterRev-social-networks a svg:hover {
      fill: #fff;
    }
  }
  .SecondaryFooter-languageSwitcher {
    text-align: left;
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-languageSwitcher {
      position: relative;
      cursor: pointer;
    }
  }
  .MainFooterRev-minifooter--socialIcons {
    width: 100%;
    margin-top: 50px;
  }
  @media screen and (min-width: 1024px) {
    .MainFooterRev-minifooter--socialIcons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      margin-top: initial;
      -ms-flex-item-align: center;
      align-self: center;
      -webkit-box-flex: 2;
      -ms-flex-positive: 2;
      flex-grow: 2;
      max-width: 60%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
  .SecondaryFooter-languageList-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 5px;
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-languageList-select {
      -ms-flex-item-align: center;
      align-self: center;
    }
  }
  .SecondaryFooter-languageList-select span {
    margin-right: 15px;
  }
  .SecondaryFooter-container-mobile {
    display: inline-block;
    float: right;
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-container-mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
  .SecondaryFooter-column {
    position: relative;
    padding-top: 30px;
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-column {
      padding-top: 0;
    }
  }
  .SecondaryFooter-column:before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #1d1d1e;
    top: 0;
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    .SecondaryFooter-column:before {
      display: none;
    }
  }
  .SecondaryFooter-english-desktop {
    position: relative;
  }
  .SecondaryFooter-english-desktop span {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 39px;
  }

  ${(p) => {
    let breakpoint = p.minimalFooter ? '1024px' : '1440px'
    let footerStyles = ``

    if (p.minimalFooter)
      footerStyles = `
        .MainFooterRev-minifooter {
          background: #1d1d1e;
        }

        .footer-down-caret {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .MainFooterRev-minifooter--socialContainer {
          padding: 48px 0 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.301961);

          @media screen and (min-width: 1025px) and (max-width: 1280px) {
            padding-left: 124px;
            padding-right: 124px;
          }
        }

        .SecondaryFooter-languageList-select {
          position: relative;
        }

        @media screen and (max-width: 1024px) {
          .MainFooterRev-minifooter--socialContainer {
            margin: 0 16px;
          }

          .SecondaryFooter-column.SecondaryFooter-column {
            border-top: 1px solid rgba(255, 255, 255, 0.301961);
          }
        }

        .MainFooterRev-minifooter--legalLinks span a.ot-sdk-show-settings {
          color: rgba(255, 255, 255, 0.301961) !important;
        }

        .MainFooterRev-minifooter--copyright span,
        .MainFooterRev-minifooter--legalLinks span a {
          color: rgba(255, 255, 255, 0.301961);
        }

      `
    else {
      footerStyles = `
        .MainFooterRev-minifooter {
          background: #111212;
        }
        .MainFooterRev-minifooter--socialContainer {
          padding: 28px 20px;
        }
        @media screen and (min-width: 768px) {
          .MainFooterRev-minifooter--socialContainer {
            border-bottom: 1px solid #1d1d1e;
          }
        }
      `
    }

    footerStyles += `
      @media screen and (min-width: ${breakpoint}) {
        .MainFooterRev-minifooter--bottomText {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
        }
        .MainFooterRev-minifooter--legalLinks {
          display: block;
        }
      }`

    return footerStyles
  }}
`

import styled from 'styled-components'

export const HeaderSearch = styled.div`
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 22px;
  width: 22px;

  .MainHeader-gcsSearchButton.searchIcon {
    display: none;
  }

  .MainHeader-gcsSearchButton.searchText {
    font-size: 16px;
    color: #273139;
    padding: 16px 16px 16px 0;
    text-transform: none;
    font-weight: 600;
  }

  @media screen and (min-width: 1025px) {
    margin: -4px 0px 4px 0;
    padding: 10px 0px 12px 4px;

    .MainHeader-gcsSearchButton.searchText {
      display: none;
    }

    .MainHeader-gcsSearchButton.searchIcon {
      display: block;
      margin-right: 40px;
      width: 22px;
      height: 22px;

      svg {
        width: 22px;
        height: 22px;
        margin: auto;
        transform: scale(1) translateZ(0);
        transform-origin: 33% 33%;
        transition: transform 0.2s ease;
        &:hover {
          fill: ${(p) => p.theme.webMktPalette.icon.blueLight};
          transform: scale(1.2);
          transition: all 0.2s ease-out;
        }
      }
    }
  }

  @media screen and (min-width: 1281px) {
    padding: 14px 0 12px 4px;
    margin: -4px 0 12px 0;
  }
`

export const HeaderSearchContainer = styled.div.attrs((props) => ({
  show: props.showSearch,
}))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  display: ${(props) => (props.show === 'yes' ? 'block' : 'none')};

  width: 100%;
  height: 100vh;

  visibility: ${(props) => (props.show === 'yes' ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show === 'yes' ? '1' : '0')};

  transition: all 0.5s ease;

  background: rgba(0, 0, 0, 0.8);

  .MainHeader__gcs__searchInputWrapper {
    position: relative;
    width: 100%;
    background-color: rgb(225, 226, 228);
  }

  .MainHeader__gcs__searchInputContainer {
    margin: 0 16px;

    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .MainHeader__gcs__searchInputLabelMobile {
    padding-bottom: 24px;

    color: #000;
    font-size: 28px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0;
  }

  .MainHeader__gcs__searchWrapperClose {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .MainHeader__gcs__searchCloseIcon {
    cursor: pointer;
  }

  @media screen and (min-width: 1025px) {
    position: fixed;
    top: 100px;
    z-index: -1;

    .MainHeader__gcs__searchInputWrapper {
      padding: 40px 0;
    }

    .MainHeader__gcs__searchInputContainer {
      position: relative;

      margin: 0 auto;
      width: 1200px;
      height: auto;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .MainHeader__gcs__searchWrapperClose {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        svg {
          cursor: pointer;
        }
      }
    }

    .MainHeader__gcs__searchInputLabelMobile {
      display: none;
    }
  }

  @media screen and (min-width: 1280px) {
    .MainHeader__gcs__searchInputContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`

export const HeaderSearchForm = styled.form`
  position: relative;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-align: center;
  width: calc(100% - 56px);

  .MainHeader__gcs__searchInputLabel {
    display: inline-block;
    margin: 0;
    padding: 0;

    color: #273139;

    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: bold;
    line-height: 20px;

    text-align: left;
  }

  .MainHeader__gcs__searchInput {
    padding: 15px 16px;

    display: block;

    width: 100%;

    border-radius: 0px;
    border: 1px solid rgb(106, 106, 106);

    color: #000;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    outline: none;
  }

  .MainHeader__gcs__searchInput:focus {
    border-color: #000;
    box-shadow: none;
  }

  input[type='submit'] {
    display: none;
  }

  .MainHeader__gcs__searchSubmitLabel {
    position: absolute;
    bottom: 0;
    right: -34px;

    display: inline-block;
    margin: 0;
    padding: 0;

    background: #000;

    width: 56px;
    height: 56px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .MainHeader__gcs__searchWrapperClose {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .MainHeader__gcs__searchCloseIcon {
    cursor: pointer;
  }

  @media screen and (min-width: 1025px) {
    margin: 0 16px;
    width: auto;

    .MainHeader__gcs__searchSubmitLabel {
      right: 0px;
    }

    .MainHeader__gcs__searchInput {
      width: 600px;
    }
  }

  @media screen and (min-width: 1280px) {
    width: auto;

    .MainHeader__gcs__searchInput {
      width: 960px;
    }
  }
`

export const MegamenuContainer = styled.ul(
  ({ $megamenuType, $greyColumnAlignment, $greyColumns, $numMenuGroups }) => {
    let bgStyles, greyColsStyles, degValue, percentOfColsGray
    if ($megamenuType === 'Product') {
      bgStyles = `
    && {
      @media screen and (min-width: 1025px) {
        background: #ffffff;
      }
      .Menu-container-label {
        font-weight: 400;
      }
    }
    `
    }

    if ($greyColumnAlignment && $greyColumnAlignment === 'Left') {
      degValue = '90deg'
    } else if ($greyColumnAlignment && $greyColumnAlignment === 'Right') {
      degValue = '270deg'
    }

    if ($numMenuGroups && $greyColumns) {
      percentOfColsGray = parseInt($greyColumns) / parseInt($numMenuGroups)
    }

    if ($greyColumnAlignment && $greyColumns) {
      greyColsStyles = `
      && {
        background: linear-gradient(${degValue}, #fafafa calc(((100% - 1260px) / 2) + 1260px * ${percentOfColsGray}), #ffffff calc(100% - 1260px));
        @media(max-width: 1260px) {
          background: linear-gradient(${degValue}, #fafafa calc(100% * ${percentOfColsGray}), #ffffff 0);
        }
        @media(max-width: 1024px) {
          background: unset;
        }
      }
    `
    } else {
      greyColsStyles = `
      background: #ffffff;
      `
    }
    return bgStyles, greyColsStyles
  }
)

export const HeaderContainer = styled.div`
  .MainHeader {
    position: relative;
    z-index: 99;
    background-color: #fff;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader {
      padding: 0 16px;
      -webkit-box-shadow: 0 3px 10px 0 rgba(8, 19, 31, 0.5);
      box-shadow: 0 3px 10px 0 rgba(8, 19, 31, 0.5);
    }
  }
  .MainHeader.MainHeader--rebranded {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .MainHeader-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2px 16px;
    font-size: 0;
    -webkit-box-shadow: 0 2px 4px 0 rgba(8, 19, 31, 0.5);
    box-shadow: 0 2px 4px 0 rgba(8, 19, 31, 0.5);
  }
  .MainHeader-nav-menu-button-container {
    @media screen and (min-width: 1025px) {
      display: flex;
    }
  }
  .MainHeader-middle-nav-container {
    margin-right: 24px;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader-container {
      padding: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .MainHeader--rebranded .MainHeader-container {
      padding: 5px 0px 0px 0px;
    }
  }
  @media screen and (min-width: 1281px) {
    .MainHeader--rebranded .MainHeader-container {
      padding: 12px 16px 0px;
    }
  }
  .MainHeader-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin-right: 8px;
  }
  .MainHeader-logo > img {
    height: 100%;
    max-width: 120px;
  }
  .MainHeader--rebranded .MainHeader-logo > img {
    max-width: 90px;
    height: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-logo > img {
      height: 32px;
      max-width: 90px;
      padding-bottom: 0;
      position: relative;
      bottom: 2px;
    }
    .MainHeader-logo {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding: 14px 0;
    }
  }
  @media screen and (min-width: 1281px) {
    .MainHeader--rebranded .MainHeader-logo > img {
      height: 48px;
      max-width: 136px;
      width: 136px; //safari fix
    }
  }
  .MainHeader-logo--maxi {
    display: block;
  }
  .MainHeader-barActions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: right;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader-barActions {
      display: none;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  }
  .MainHeader-menuButton {
    position: relative;
    width: 48px;
    height: 48px;
    font-size: 0;
    @media screen and (min-width: 1025px) {
      margin-left: 16px;
    }
  }
  .MainHeader-menuButton:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 32px;
    height: 4px;
    margin: auto;
    border-radius: 1px;
    opacity: 1;
    background-color: #d3dee3;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
  }
  .MainHeader--rebranded .MainHeader-menuButton:after {
    width: 20px;
    height: 2px;
    background-color: #000;
  }
  .MainHeader-menuButtonIcon {
    position: relative;
    margin: auto;
  }
  .MainHeader--rebranded .MainHeader-menuButtonIcon {
    width: 20px;
    height: 14px;
  }
  .MainHeader-menuButtonIcon:after,
  .MainHeader-menuButtonIcon:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #d3dee3;
    height: 4px;
    border-radius: 1px;
    -webkit-transform: rotate(0) translateZ(0);
    transform: rotate(0) translateZ(0);
    -webkit-transition: top 0.2s ease, bottom 0.2s ease,
      -webkit-transform 0.2s ease;
    transition: top 0.2s ease, bottom 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, top 0.2s ease, bottom 0.2s ease;
    transition: transform 0.2s ease, top 0.2s ease, bottom 0.2s ease,
      -webkit-transform 0.2s ease;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .MainHeader--rebranded .MainHeader-menuButtonIcon:after,
  .MainHeader--rebranded .MainHeader-menuButtonIcon:before {
    background-color: #000;
    height: 2px;
  }
  .MainHeader-menuButtonIcon:after {
    top: auto;
  }
  .MainHeader-menuContainer {
    position: relative;
    width: 100%;
    margin: 10px 0 12px;
    padding: 8px 0;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: none;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin: 0;
      padding: 0;
    }
  }
  .MainHeader--rebranded .MainHeader-menuContainer {
    position: static;
  }
  .MainHeader-menuContainer:before {
    content: '';
    position: absolute;
    left: -16px;
    right: -16px;
    bottom: 100%;
    width: calc(100% + 32px);
    height: 60px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(8, 19, 31, 0.1);
    box-shadow: 0 2px 4px 0 rgba(8, 19, 31, 0.1);
    pointer-events: none;
  }
  .MainHeader--rebranded .MainHeader-menuContainer:before {
    display: none;
    content: none;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader-menuContainer {
      display: block;
      width: auto;
      margin: 0;
      padding: 0;
      border: 0;
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      -ms-flex-item-align: stretch;
      align-self: stretch;
    }
    .MainHeader-menuContainer:before {
      content: none;
    }
  }
  .MainHeader-CTA--Mobile {
    display: none;
    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 100%;
      box-sizing: border-box;
      order: 2;
      margin: 32px 0;
    }
  }
  .MainHeader-CTA {
    width: 100%;
    margin: 0 0 12px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -ms-flex-item-align: center;
    align-self: center;
    text-transform: none;
  }
  @media screen and (min-width: 480px) {
    .MainHeader-CTA {
      width: auto;
      margin: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }
  }
  @media screen and (max-width: 1024px) {
    .MainHeader-CTA {
      display: none;
    }
    .MainHeader-middle-nav-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-right: 0;
      margin-top: 60px;
    }
    .MainHeader-topBar {
      order: 2;
    }
    .MainHeader-menuContainer {
      order: 1;
    }

    div.MainHeader-barActions {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
  }

  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-CTA {
      padding: 8px;
      display: block;
      border-radius: initial;
      font-size: 12px;
      font-weight: 700;
    }
    .MainHeader-CTA {
      position: relative;
      width: auto;
      margin-left: 8px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 12px;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-CTA {
      font-size: 16px;
      padding: 11px 16px;
    }
    .MainHeader-CTA {
      margin-left: 16px;
      padding: 0.5em 1.75em;
      font-size: 14px;
    }
  }

  .MainHeader-contactButton {
    position: relative;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    color: rgba(0, 40, 85, 0.5);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-contactButton:hover {
      color: #0067df;
      text-decoration: underline;
    }
  }
  .MainHeader--rebranded .MainHeader-contactButton {
    font-size: 14px;
    color: #273139;
    padding: 14px 0 12px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      padding: 8px 0;
      line-height: 24px;
    }
  }
  .MainHeader-topBar-links {
    padding-top: 14px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 0;
      padding-top: 0;
      width: 100%;
    }
  }
  .MainHeader-menuContainer--languageSwitcher {
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin: 0;
      border-bottom: 0;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-contactButton {
      padding-bottom: 0;
      font-size: 12px;
    }
    .MainHeader--rebranded .MainHeader-contactButton.signInMargin {
      margin-right: 186px;
      font-size: 12px;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-contactButton {
      padding-bottom: 0;
    }
    .MainHeader--rebranded .MainHeader-contactButton.signInMargin {
      margin-right: 216px;
    }
  }
  .MainHeader-contactButton > img {
    width: 18px;
    margin-right: 8px;
    margin-bottom: 1px;
    vertical-align: middle;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader-contactButton {
      font-size: 12px;
      padding-right: 24px;
      margin-right: 14px;
    }
    .MainHeader-contactButton:last-child {
      margin-right: 0;
    }
    .MainHeader-contactButton:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 12px;
      margin: auto 0;
      background-color: rgba(0, 40, 85, 0.5);
    }
    .MainHeader--rebranded .MainHeader-contactButton:before {
      display: none;
    }
    .MainHeader-contactButton:hover {
      color: #4a4a4a;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader-contactButton {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 1281px) {
    .MainHeader-contactButton {
      margin-right: 30px;
    }
  }
  .MainHeader-topBar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .MainHeader--rebranded .MainHeader-topBar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-topBar {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: end;
    }
  }
  @media screen and (max-width: 1024px) {
    .MainHeader-topBar {
      display: none;
    }
  }
  @media screen and (min-width: 1281px) {
    .MainHeader-topBar {
      top: 3px;
    }
  }
  @media screen and (min-width: 480px) {
    .MainHeader-topBar {
      width: auto;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      margin-left: 36px;
    }
    .MainHeader--rebranded .MainHeader-topBar {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 1025px) {
    .MainHeader-topBar {
      position: relative;
      width: auto;
      margin-left: 0;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
      text-align: right;
    }
    .MainHeader-topBar:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc((100vw - 100%) / -2);
      right: calc((100vw - 100%) / -2);
      height: 1px;
      background-color: #d3dee3;
    }
    .MainHeader--rebranded .MainHeader-topBar:before {
      display: none;
    }
  }
  .MainHeader-mobileContact {
    position: absolute;
    left: 0;
    top: 0;
    width: 70%;
    background-color: #fa4616;
    height: 64.8px;
  }
  .MainHeader--rebranded .MainHeader-mobileContact {
    height: 64px;
  }
  @media screen and (min-width: 1025px) {
    .MainHeader--rebranded .MainHeader-mobileContact {
      display: none;
    }
  }
  .MainHeader-mobileContact a {
    color: #fff;
    font-size: 16px;
    display: block;
    line-height: 64px;
    margin-left: 16px;
    font-weight: 700;
  }
  .MainHeader--rebranded .MainHeader-mobileContact a {
    line-height: 34px;
    padding-left: 16px;
    font-weight: 700;
    text-align: left;
  }
  .MainHeader-mobileContact a span {
    position: relative;
  }
  .MainHeader-mobileContact a span:after {
    position: absolute;
    right: -54px;
    top: 5px;
    content: url('https://www.uipath.com/hubfs/resources/icons/24x24_ArrowLong_Right.svg');
    line-height: 0;
    font-size: 32px;
  }
  .MainHeader-mobileContactBackground {
    position: absolute;
    background: #000;
    right: 0;
    top: 0;
    width: 30%;
    height: 64.8px;
    z-index: 0;
  }
  .MainHeader--rebranded .MainHeader-mobileContactBackground {
    height: 64px;
  }
`

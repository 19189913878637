import styled from 'styled-components'

export const ImageContainer = styled.div`
  ${(p) =>
    p.enlargeable
      ? `
      position: relative;
      img {
        display: block;
      }
      svg {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;
        background: #fff;
        width: 48px;
        height: 48px;
        box-sizing: border-box;
        padding: 16px;

        .search-icon {
          fill: #000;
        }
      }
      &:hover {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.2);
          z-index: 3;
        }
        svg {
          background: #000;
          .search-icon {
            fill: #fff;
          }
        }
      }
    `
      : ``}

  ${({ $outerPage, theme }) => {
    if ($outerPage && $outerPage === 'aboutRPA') {
      return `
      background: ${theme.webMktPalette.background.gray};
      text-align: center;
      .Image__Element {
        width: 792px;
        height: auto;
        margin: 0 auto;
        ${theme.breakpoints.down('md')} {
          width: 796px;
        }
        ${theme.breakpoints.down('sm')} {
          width: 536px;
        }
        ${theme.breakpoints.down('xs')} {
          width: 328px;
        }
      }
    `
    }
  }}
`

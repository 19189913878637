import React, { useRef, useEffect, useState } from 'react'

// HELPERS
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'
import { ModuleNames } from '../../../constants/componentNames.constants'

// STYLES
import { ContentRowsContainer, AgendaContainer, FAQContainer } from './style'

// COMPONENTS
import GlobalContainer from '../globalContainer'
import Typography from '../typography'

const FAQ = (props: any) => {
  const [openFAQFromHash, setOpenFAQFromHash] = useState(false)

  if (props.data) {
    const title =
      props.data.title && props.data.title.title ? props.data.title.title : ''
    const body = props.data.body ? props.data.body : ''
    const idx = props.idx ? props.idx : 0

    useEffect(() => {
      if (
        body.references &&
        body.references[0] &&
        body.references[0].__typename &&
        body.references[0].__typename === ModuleNames.ANCHOR_LINK &&
        body.references[0].identifier
      ) {
        if (typeof window !== 'undefined') {
          if (window.location.hash === `#${body.references[0].identifier}`) {
            setOpenFAQFromHash(true)
          }
        }
      }
    }, [])

    useEffect(() => {
      if (openFAQFromHash) {
        // scrollOffSet includes height of page Sticky Nav  and height of faq title header
        if (typeof window !== 'undefined') {
          const scrollOffSet =
            window.innerWidth <= 1025
              ? 222
              : window.innerWidth <= 1280
              ? 272
              : 322
          const anchorLink = window.document.getElementById(
            body.references[0].identifier
          )
          if (anchorLink) {
            const y =
              anchorLink.getBoundingClientRect().top +
              window.scrollY -
              scrollOffSet
            window.scroll({
              top: y,
              behavior: 'smooth',
            })
          }
        }
      }
    }, [openFAQFromHash])

    return (
      <li
        {...(idx === 0 && {
          className: 'FAQ-Item__Active--Desktop',
        })}
        key={`FAQ-Item__Menu-${idx}`}
        className={`FAQ__Item-Container ${
          openFAQFromHash ? 'FAQ-Item__Active' : ''
        }`}
        onClick={() => {
          props.toggleDrawer(idx)
        }}
      >
        <div className="FAQ__Item">
          {title && (
            <div className="FAQ__Item-Header-Container">
              <Typography type="header6" className="FAQ-Item__Label">
                {title}
              </Typography>
            </div>
          )}
          {body && (
            <div className="FAQ__Item-Body FAQ-Item__Accordion-Item">
              <Typography type="body" className="FAQ-Item__Item-Body">
                {renderRichText(body, options)}
              </Typography>
            </div>
          )}
        </div>
      </li>
    )
  } else {
    return null
  }
}

const Agenda = (props: any) => {
  if (props.data) {
    const title =
      props.data.title && props.data.title.title ? props.data.title.title : ''
    const label = props.data.label ? props.data.label : ''
    const body = props.data.body ? props.data.body : ''
    return (
      <div className="Agenda__Item">
        {label && (
          <Typography type="captionAlt" className="Agenda__Item-Label">
            {label}
          </Typography>
        )}
        {title && (
          <Typography type="header6" className="Agenda__Item-Title">
            {title}
          </Typography>
        )}
        {body && (
          <div className="Agenda__Item-Body">
            <Typography type="body">{renderRichText(body, options)}</Typography>
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

const ContentRows = (props: any) => {
  if (props.data) {
    const tabs = useRef<HTMLUListElement>()
    const topPadding = props.data.topPadding ? props.data.topPadding : ''
    const bottomPadding = props.data.bottomPadding
      ? props.data.bottomPadding
      : ''
    const background = props.data.theme ? props.data.theme : ''
    const useCase = props.data.useCase ? props.data.useCase : ''
    const rows = props.data.rows ? props.data.rows : []
    const title = props.data.title ? props.data.title : ''
    const isAgenda = useCase && useCase === 'Agenda'
    const isAccordion = useCase && useCase === 'Accordion'

    const toggleDrawer = (idx: number) => {
      // For Accordion Tab
      if (tabs?.current?.children[idx].classList.contains('FAQ-Item__Active')) {
        tabs?.current?.children[idx].classList.remove('FAQ-Item__Active')
      } else {
        tabs?.current?.children[idx].classList.add('FAQ-Item__Active')
      }
    }

    const renderRowContainers = () => {
      if (rows && rows.length > 1) {
        const inner = rows.map((module: any, i: Number) => {
          switch (module.__typename) {
            case ModuleNames.TOPIC_FAQ:
              return (
                <FAQ
                  data={module}
                  idx={i}
                  key={`content-rows-inner--${module.__typename}-${i}`}
                  toggleDrawer={toggleDrawer}
                ></FAQ>
              )
            case ModuleNames.TOPIC_AGENDA:
              return (
                <Agenda
                  data={module}
                  idx={i}
                  key={`content-rows-inner--${module.__typename}-${i}`}
                ></Agenda>
              )
          }
        })
        if (isAgenda) {
          return (
            <AgendaContainer>
              {title && <Typography type="labelAlt">{title}</Typography>}
              {inner}
            </AgendaContainer>
          )
        } else if (isAccordion) {
          return (
            <GlobalContainer>
              <FAQContainer>
                {title && <Typography type="labelAlt">{title}</Typography>}
                <ul
                  className="ContentRows__FAQ-Container"
                  ref={(tab: any) => (tabs.current = tab)}
                >
                  {inner}
                </ul>
              </FAQContainer>
            </GlobalContainer>
          )
        }
      } else {
        return null
      }
    }

    return (
      <ContentRowsContainer
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        backgroundTheme={background}
        useCase={useCase}
        data-cy={isAgenda ? 'ContentRows--Agenda' : 'ContentRows--Accordion'}
      >
        {renderRowContainers()}
      </ContentRowsContainer>
    )
  }
  return null
}

export default ContentRows

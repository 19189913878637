// Libraries
import React, { useState } from 'react'

// Components
import Search from '../search'
import { HeaderContainer } from './minHeader'

// Images
import full_logo from '../../../../static/steam-img/UiPath-full-logo.svg'

// Constants
import { ModuleNames } from '../../../constants/componentNames.constants'

// Helpers
import { isExternalURL, createLinkBasedOnType } from '../../../utils/link.utils'
import minHeaderStaticQuery from './minHeaderStaticQuery'

type MenuGroupProps = {
  newGroupName: string
  pages: any
  parentLink?: any
}

const languageSelect = {
  default: 'English',
  rows: [
    { title: 'Deutsch', href: 'https://uipath.com/de/', lang: 'de' },
    { title: 'Français', href: 'https://uipath.com/fr/', lang: 'fr' },
    {
      title: 'Portuguese',
      href: 'https://uipath.com/pt/',
      lang: 'pt',
    },
    { title: 'Spanish', href: 'https://uipath.com/es/', lang: 'es' },
    {
      title: '简体中文',
      href: 'https://www.uipath.com.cn/',
      lang: 'cn',
    },
    { title: '日本語', href: 'https://uipath.com/ja/', lang: 'ja' },
    { title: '대한민국', href: 'https://uipath.com/ko/', lang: 'ko' },
  ],
}

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [timer, setTimer] = useState(null)
  const [showSearch, setShowSearch] = useState(false)

  let bodyElement: any

  const dynamicData = minHeaderStaticQuery.allContentfulNavMenu()

  const handleMenuOpen = (event: any) => {
    setMenuOpen(!menuOpen)
    document.body.classList.toggle('scroll-lock')
    let menuBtn = document.querySelector('.MinHeader-menuBtn')
    if (menuBtn) {
      menuBtn.classList.toggle('opened')
      menuBtn.setAttribute(
        'aria-expanded',
        menuBtn.classList.contains('opened')
      )
    }
    let minHeaderMenu = document.querySelector('.MinHeader-menu')
    if (minHeaderMenu) {
      minHeaderMenu.classList.toggle('opened')
    }
  }

  const handleAccordionOpen = (event: any) => {
    let languageSelector = document.querySelector(
      '.MinHeader-deepList--languageSwitcher'
    )
    if (languageSelector) {
      languageSelector.classList.remove('is-active')
    }
    let currentBtn = event.currentTarget
    currentBtn.classList.toggle('open')
    event.currentTarget.nextSibling.classList.toggle('is-active')
    let btnContainers = document.querySelectorAll(
      '.MinHeader-MenuItemBtn-Container'
    )
    let menuItems = document.querySelectorAll('.MinHeader-SubMenu')
    for (let btn of btnContainers) {
      if (btn !== currentBtn) btn.classList.remove('open')
    }
    for (let item of menuItems) {
      if (item !== event.currentTarget.nextSibling)
        item.classList.remove('is-active')
    }
  }

  const handleLanguageSwitcherOpen = (event: any) => {
    let currentSubMenu =
      event.currentTarget.nextSibling.classList.contains('is-active')
    if (currentSubMenu) {
      event.currentTarget.nextSibling.classList.remove('is-active')
    } else {
      let btnContainers = document.querySelectorAll(
        '.MinHeader-MenuItemBtn-Container'
      )
      let menuItems = document.querySelectorAll('.MinHeader-SubMenu')
      for (let btn of btnContainers) {
        btn.classList.remove('open')
      }
      for (let item of menuItems) {
        item.classList.remove('is-active')
      }
      event.currentTarget.nextSibling.classList.add('is-active')
      let minHeaderMenu = document.querySelector('.MinHeader-menu')
      if (minHeaderMenu) {
        minHeaderMenu.scrollTop =
          minHeaderMenu.scrollHeight - minHeaderMenu.clientHeight
      }
    }
  }

  const handleSearchClick = (e: any) => {
    e.preventDefault()
    if (!showSearch) {
      let searchInput = document.querySelector('#gcs__input')
      if (searchInput) {
        searchInput.focus()
        if (bodyElement) {
          bodyElement.classList.add('overflow-hidden')
        }
      }
    } else {
      if (bodyElement) {
        bodyElement.classList.remove('overflow-hidden')
      }
    }
    setShowSearch(!showSearch)
  }

  const createTextBasedOnType = (item: any) => {
    if (item && item.__typename === ModuleNames.TOPIC_CTA) {
      return item.text
    }
  }

  const getAllNestedPages = (pages: any) => {
    let allPages: any = []
    for (let page of pages) {
      if (page.pages) {
        for (let nestedPage of page.pages) {
          allPages.push(nestedPage)
        }
      } else {
        allPages.push(page)
      }
    }
    return allPages ? allPages : pages
  }

  const dynamicNav = () => {
    const menuGroups =
      dynamicData &&
      dynamicData.contentfulNavMenu &&
      dynamicData.contentfulNavMenu.menuGroups &&
      dynamicData.contentfulNavMenu.menuGroups.length !== 0
        ? dynamicData.contentfulNavMenu.menuGroups
        : []

    const primaryCta =
      dynamicData &&
      dynamicData.contentfulNavMenu &&
      dynamicData.contentfulNavMenu.primaryCta
        ? dynamicData.contentfulNavMenu.primaryCta
        : {}

    return (
      <HeaderContainer data-cy="MinHeader">
        {menuOpen && (
          <div className="MinHeader-overlay" onClick={handleMenuOpen}></div>
        )}
        <header className="MinHeader">
          {!menuOpen && (
            <a href="/" rel="noopener">
              <img
                className="MinHeader-logo"
                src={full_logo}
                alt="UiPath Logo"
              />
            </a>
          )}

          <div className="MinHeader-menu">
            <button
              className="MinHeader-menuBtn"
              aria-label="Main Menu"
              onClick={handleMenuOpen}
            >
              <span className="MinHeader-menuBtn-text"></span>
              <svg viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
            <nav className="MinHeader-menuContainer">
              <ul className="MinHeader-Menu-list">
                {menuGroups.map((menuGroup: MenuGroupProps, i: number) => {
                  let hasPages = menuGroup.pages
                  if (hasPages) {
                    menuGroup.pages = getAllNestedPages(menuGroup.pages)
                  }
                  const megamenuSubGroups = hasPages
                    ? menuGroup.pages.map((megamenuPage: any, n: number) => {
                        let target
                        const linkHref = createLinkBasedOnType(megamenuPage)
                        const linkTitle = createTextBasedOnType(megamenuPage)
                        if (isExternalURL(linkHref)) {
                          target = '_blank'
                        } else {
                          target = '_self'
                        }
                        return (
                          <a
                            className="MinHeader-SubMenuItem"
                            key={`${linkTitle}--${n}`}
                            href={linkHref}
                            rel="noopener"
                            target={target}
                          >
                            {linkTitle}
                          </a>
                        )
                      })
                    : []
                  return (
                    <li className="MinHeader-MenuItem" key={i}>
                      <button
                        className="MinHeader-MenuItemBtn-Container"
                        onClick={handleAccordionOpen}
                      >
                        <span className="MinHeader-MenuItemBtn">
                          {menuGroup.newGroupName}
                        </span>
                      </button>
                      <ul className="MinHeader-SubMenu">{megamenuSubGroups}</ul>
                    </li>
                  )
                })}
              </ul>
            </nav>

            <div
              className="headerSearch"
              data-cy="headerSearch"
              onClick={handleSearchClick}
            >
              <a className="MinHeader-searchBtn">Search</a>
            </div>

            {primaryCta && primaryCta.externalUrl && primaryCta.text && (
              <a
                className="MinHeader-CTA Button Button--primary"
                href={primaryCta.externalUrl}
                rel="noopener"
              >
                {primaryCta.text}
              </a>
            )}

            <Search open={showSearch} handleSearchClick={handleSearchClick} />

            <div className="MinHeader-topBar">
              <div className="MinHeader-topBar-links">
                {dynamicData &&
                  dynamicData.contentfulNavMenu &&
                  dynamicData.contentfulNavMenu.topHatLinks &&
                  dynamicData.contentfulNavMenu.topHatLinks.map(
                    (topHatLink: any, j: number) => {
                      let target
                      if (isExternalURL(topHatLink.externalUrl)) {
                        target = '_blank'
                      } else {
                        target = '_self'
                      }

                      return (
                        <a
                          className={`MinHeader-contactButton`}
                          href={topHatLink.externalUrl}
                          target={target}
                          rel="noopener"
                          key={`topHatLink--${j}`}
                        >
                          {topHatLink.text}
                        </a>
                      )
                    }
                  )}
              </div>

              <nav className="MinHeader-menuContainer--languageSwitcher">
                <button
                  className="MinHeader-btn--languageSwitcher"
                  onClick={handleLanguageSwitcherOpen}
                >
                  {languageSelect.default}
                </button>
                <ul className="MinHeader-deepList--languageSwitcher">
                  {languageSelect.rows.map((row: any, i: number) => (
                    <li
                      className="MinHeader-listItem--languageSwitcher"
                      key={`language-select--${i}`}
                    >
                      <a
                        className="MinHeader-link--languageSwitcher"
                        data-language={row.lang}
                        href={row.href}
                        rel="noopener"
                      >
                        <span>{row.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </HeaderContainer>
    )
  }

  if (dynamicData) {
    return dynamicNav()
  }
  return null
}

export default Header

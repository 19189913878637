export const generateBlogPostSlug = (
  slug: string,
  blogCategoryName: string
) => {
  if (!slug || !blogCategoryName) return ''
  let transformed = blogCategoryName
    .toLowerCase()
    .replace(/ *\([^)]*\) */g, '')
    .replace(/\s/g, '-')
  if (transformed === 'artificial-intelligence') {
    transformed = 'ai'
  } else if (transformed === 'robotic-process-automation') {
    transformed = 'rpa'
  }
  return `/blog/${transformed}/${slug}`
}

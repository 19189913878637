import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

// COMPONENTS
import Image from '../components/global/image'
import ContentRows from '../components/global/contentRows'
import VideoPlayer from '../components/global/videoPlayer'
import { ModuleNames } from '../constants/componentNames.constants'
import { BlogQuoteWHeadshot } from '../components/global/quote/quote'
import { InlineAuthor } from '../components/page/resources/formPage'
import Button from '../components/global/button'
import Table from '../components/global/table'

// Typography
import Typography from '../components/global/typography'
import {
  Body,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
} from '../components/global/typography/typography.js'

// HELPERS
import formatInlineCode, {
  addClassToLastElementOfReadOnlyJSX,
} from './shortTextFormatting'
import {
  isExternalURL,
  correctSlugPrefix,
  internalLinkHandler,
} from './link.utils'
import ImgUtil from './img.utils'
import WrapperImage from '../components/global/wrapperImage'

const paragraph = (node: any, children: any) => {
  if (node) {
    if (children && children.length === 1 && typeof children[0] === 'string') {
      // handles the case where a paragraph has a single child that is string
      return <Body>{formatInlineCode(children[0])}</Body>
    } else if (children && children.length === 3 && children[1]?.type === 'a') {
      // handles paragraphs with a links
      return handleLinksWithLinebreaks(children)
    } else {
      return <Body>{children}</Body>
    }
  }
}

const entryHyperlink = (node: any, children: any) => {
  if (node.data && node.data.target && node.data.target.__typename) {
    switch (node.data.target.__typename) {
      case ModuleNames.WRAPPER_ASSET:
        const linkText =
          node.content && node.content[0] && node.content[0].value
            ? node.content[0].value
            : ''
        const altText =
          node.data.target.image && node.data.target.image.altText
            ? node.data.target.image.altText
            : ''
        let imgURL =
          node.data.target.image &&
          node.data.target.image.file &&
          node.data.target.image.file.url
            ? `https:${node.data.target.image.file.url}`
            : ''
        let imageData = {
          imageFile: {
            file: {
              title: altText,
              url: imgURL,
            },
          },
        }
        return imgURL && linkText ? (
          <a href={linkText}>
            <Image data={imageData} />
          </a>
        ) : (
          ''
        )
      case ModuleNames.HOMEPAGE:
        return <a href="/">{node.content[0].value}</a>
      default:
        const slug = node.data.target.slug ? node.data.target.slug : ''
        const text =
          node.content && node.content[0] && node.content[0].value
            ? node.content[0].value
            : ''
        // slug prefix for blog posts
        const prefix =
          (node.data.target.__typename === ModuleNames.BLOG_POST &&
            node.data.target.blogCategory?.seoMetadata?.pageCanonicalUrl) ||
          ''
        return slug && text ? (
          <a href={`${prefix}${correctSlugPrefix(slug)}`}>{children}</a>
        ) : (
          ''
        )
    }
  }
}

const hyperlink = (node: any) => {
  if (node.data && node.data.uri) {
    const nodeContent = node.content ? node.content : null
    let linkText = ``
    if (nodeContent) {
      for (let content of nodeContent) {
        let value = content.value
        if (content.marks) {
          for (let mark of content.marks) {
            if (mark.type === 'bold') {
              value = `<strong>${value}</strong>`
            } else if (mark.type === 'italic') {
              value = `<em>${value}</em>`
            }
          }
        }
        if (value) {
          linkText += value
        }
      }
    }
    if (isExternalURL(node.data.uri)) {
      return (
        <a
          href={node.data.uri}
          target="_blank"
          rel="noopener"
          dangerouslySetInnerHTML={{ __html: linkText }}
        ></a>
      )
    } else {
      return (
        <a
          href={node.data.uri}
          dangerouslySetInnerHTML={{ __html: linkText }}
        ></a>
      )
    }
  }
}

const handleLinksWithLinebreaks = (children: any) => {
  if (children && children[0] && children[1] && children[2]) {
    let formattedText = formatInlineCode(children[0])
    if (formattedText !== '')
      addClassToLastElementOfReadOnlyJSX(
        formattedText as JSX.Element[],
        'inline-formatter'
      )
    return (
      <Body>{[formattedText, children[1], formatInlineCode(children[2])]}</Body>
    )
  } else {
    return <Body>{children}</Body>
  }
}

const generateByline = (
  authorName: string,
  authorTitle: string,
  authorCompany: string
) => {
  let byline = ''
  if (authorName) {
    byline += authorName
    if (authorTitle) {
      byline += ` · ${authorTitle}`
      if (authorCompany) {
        byline += ` at ${authorCompany}`
      }
    }
  }
  return byline
}

const renderLink = (node: any) => {
  const externalLink = node?.data?.target?.externalUrl
    ? node.data.target.externalUrl
    : ''
  const internalLink = node?.data?.target?.internalLink
    ? internalLinkHandler(node.data.target.internalLink)
    : ''
  const text = node?.data?.target?.text ? node?.data?.target?.text : ''

  let link
  if (externalLink) {
    link = externalLink
  } else if (internalLink) {
    link = internalLink
  } else {
    link = ''
  }

  if (link) {
    return (
      <div className="Inline-CTA-Container">
        <Button
          href={link}
          icon={true}
          text={text}
          type="inline-text-link"
          size="small"
          theme="light"
        />
      </div>
    )
  }
}

const embeddedEntry = (node: any) => {
  if (
    node.data &&
    node.data.target &&
    node.data.target.__typename === ModuleNames.WRAPPER_ASSET
  ) {
    let enlargeable = node.data.target.enlargeable
    let altText = node.data.target.altText ? node.data.target.altText : ''
    let imageData = {
      imageFile: {
        title: altText,
        file: {
          url: `https://${node.data.target.image.file.url}`,
        },
      },
    }
    return <Image enlargeable={enlargeable} data={imageData} />
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename === ModuleNames.QUOTE
  ) {
    const data = node.data.target
    const imageSrc =
      data.image &&
      data.image.image &&
      data.image.image.file &&
      data.image.image.file.url
        ? ImgUtil.getResizedImageUrl(data.image.image.file.url, `width=250`)
        : '/'

    const imageAlt =
      data.image && data.image.altText
        ? data.image.altText
        : 'Quote Author Headshot'
    const quoteBody = data.body && data.body.body ? data.body.body : ''
    const authorName = data.authorName ? data.authorName : ''
    const authorTitle = data.authorTitle ? data.authorTitle : ''
    const authorCompany = data.company ? data.company : ''

    let byline = generateByline(authorName, authorTitle, authorCompany)

    return (
      <BlogQuoteWHeadshot>
        {quoteBody && <Typography type="lead">{quoteBody}</Typography>}
        <div className="BlockQuote__Headshot-Img">
          <WrapperImage
            className="BlockQuote__Headshot-Img-File"
            alt={imageAlt}
            src={imageSrc}
            width={160}
            height={160}
            threshold={650}
          />
        </div>
        <Typography className="BlockQuote__Byline" type="label">
          {byline}
        </Typography>
      </BlogQuoteWHeadshot>
    )
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename === ModuleNames.WRAPPER_VIDEO
  ) {
    let asset =
      node.data.target.animationFile && node.data.target.animationFile.file
        ? node.data.target.animationFile.file.url
        : ''
    let assetAltText = node.data.target.altText ? node.data.target.altText : ''
    let videoId, type, videoCoverImage, videoCoverAlt, videoStart
    if (node.data.target.youtubeId) {
      videoId = node.data.target.youtubeId
      type = 'youtube'
    } else if (node.data.target.vimeoId) {
      videoId = node.data.target.vimeoId
      type = 'vimeo'
    }
    if (
      node.data.target.image &&
      node.data.target.image.file &&
      node.data.target.image.file.url
    ) {
      videoCoverImage = node.data.target.image.file.url
    }
    if (node.data.target.altText) {
      videoCoverAlt = node.data.target.altText
    }
    if (node.data.target.start) {
      videoStart = node.data.target.start
    }
    if (asset) {
      let last = asset.lastIndexOf('.')
      return asset.substring(last).includes('.mp4') ? (
        <video src={asset} playsInline />
      ) : (
        <WrapperImage
          alt={assetAltText}
          src={asset}
          threshold={650}
        />
      )
    } else if (videoId && videoCoverImage) {
      return (
        <VideoPlayer
          width={'100%'}
          height="429"
          videoId={videoId}
          type={type}
          img={videoCoverImage}
          backgroundAltText={videoCoverAlt}
          start={videoStart}
        />
      )
    }
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.TOPIC_CUSTOMER_DETAILS
  ) {
    let data = node.data.target
    let image = ''
    let altText = ''
    if (
      data.colorLogo &&
      data.colorLogo.image &&
      data.colorLogo.image.file &&
      data.colorLogo.image.file.url
    ) {
      if (data.colorLogo.altText) altText = data.colorLogo.altText
      image = data.colorLogo.image.file.url
    }

    if (image && altText) return <WrapperImage src={image} alt={altText} threshold={650} />
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.BIO
  ) {
    const data = node.data.target
    const imageSrc =
      data.headshot &&
      data.headshot.image &&
      data.headshot.image.file &&
      data.headshot.image.file.url
        ? ImgUtil.getResizedImageUrl(data.headshot.image.file.url, `width=250`)
        : '/'

    const imageAlt =
      data.headshot && data.headshot.altText
        ? data.headshot.altText
        : 'Headshot image'

    const authorFirstName = data.firstName ? data.firstName : ''
    const authorLastName = data.lastName ? data.lastName : ''
    const authorName = authorFirstName + ' ' + authorLastName
    const authorTitle = data.role ? data.role : ''
    const authorCompany = data.company ? data.company : ''

    let byline
    if (authorTitle && authorCompany) {
      byline = `${authorTitle} at ${authorCompany}`
    } else if (authorTitle) {
      byline = authorTitle
    } else if (authorCompany) {
      byline = authorCompany
    }
    return (
      <InlineAuthor>
        <div className="InlineAuthor__Headshot-Img">
          <WrapperImage
            className="InlineAuthor__Headshot-Img-File"
            alt={imageAlt}
            src={imageSrc}
            width={80}
            height={80}
            threshold={650}
          />
        </div>
        <div className="InlineAuthor__Information">
          <Typography className="InlineAuthor__Name" type="label">
            {authorName}
          </Typography>
          <Typography className="InlineAuthor__Byline" type="caption">
            {byline}
          </Typography>
        </div>
      </InlineAuthor>
    )
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.ANCHOR_LINK
  ) {
    const identifier = node?.data?.target?.identifier
      ? node?.data?.target?.identifier
      : ''
    return <span id={identifier}></span>
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.CONTENT_ROWS
  ) {
    return <ContentRows data={node.data.target} />
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.TOPIC_CTA
  ) {
    const externalLink = node?.data?.target?.externalUrl
      ? node.data.target.externalUrl
      : ''
    const internalLink = node?.data?.target?.internalLink
      ? internalLinkHandler(node.data.target.internalLink)
      : ''
    const text = node?.data?.target?.text ? node?.data?.target?.text : ''

    let link
    if (externalLink) {
      link = externalLink
    } else if (internalLink) {
      link = internalLink
    } else {
      link = ''
    }

    if (link) {
      return (
        <div className="Inline-CTA-Container">
          <Button
            href={link}
            icon={true}
            text={text}
            type="primary"
            size="small"
            theme="light"
          />
        </div>
      )
    }
  } else if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.TABLE
  ) {
    return <Table data={node.data.target} />
  }
}

const listItem = (node: any, children: any) => {
  if (node && children) {
    return <li className="richText-styled-list-items">{children}</li>
  }
}

const listStyle = (node: any, children: any) => {
  if (node && children) {
    return node.nodeType === 'unordered-list' ? (
      <ul className="richText-styled-list">{children}</ul>
    ) : (
      <ol className="richText-styled-list">{children}</ol>
    )
  }
}

const quoteBlock = (node: any, children: any) => {
  if (node && node.content) {
    let isQuote = ''
    if (
      node.content[0] &&
      node.content[0].content &&
      node.content[0].content[0]
    ) {
      let text = node.content[0].content[0].value || ''
      isQuote =
        text.startsWith('"') || text.startsWith("'") || text.startsWith('“')
      if (isQuote && children && children.length > 1) {
        text = text.slice(1, -1)
        let modifiedChild = { ...children[0] }
        let modifiedChildProps = {
          ...modifiedChild.props,
          ...{ children: text },
        }
        return (
          <blockquote
            className={`quote-container ${isQuote ? 'is-quote' : ''}`}
          >
            {[
              { ...modifiedChild, props: modifiedChildProps },
              { ...children[1] },
            ]}
          </blockquote>
        )
      }
    }
    return children ? (
      <blockquote className={`quote-container ${isQuote ? 'is-quote' : ''}`}>
        {children}
      </blockquote>
    ) : null
  }
}

const inlineEmbeddedEntry = (node: any) => {
  if (
    node.data &&
    node.data.target &&
    node.data.target.__typename &&
    node.data.target.__typename === ModuleNames.TOPIC_CTA
  ) {
    return renderLink(node)
  }
}

//RICH TEXT OPTIONS
const options = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: entryHyperlink,
    [INLINES.HYPERLINK]: hyperlink,
    [INLINES.EMBEDDED_ENTRY]: inlineEmbeddedEntry,
    [BLOCKS.EMBEDDED_ENTRY]: embeddedEntry,
    [BLOCKS.HEADING_2]: (_: any, children: any) => (
      <Header2>{children}</Header2>
    ),
    [BLOCKS.HEADING_2]: (_: any, children: any) => (
      <Header2>{children}</Header2>
    ),
    [BLOCKS.HEADING_3]: (_: any, children: any) => (
      <Header3>{children}</Header3>
    ),
    [BLOCKS.HEADING_4]: (_: any, children: any) => (
      <Header4>{children}</Header4>
    ),
    [BLOCKS.HEADING_5]: (_: any, children: any) => (
      <Header5>{children}</Header5>
    ),
    [BLOCKS.HEADING_6]: (_: any, children: any) => (
      <Header6>{children}</Header6>
    ),
    [BLOCKS.PARAGRAPH]: paragraph,
    [BLOCKS.UL_LIST]: listStyle,
    [BLOCKS.OL_LIST]: listStyle,
    [BLOCKS.LIST_ITEM]: listItem,
    [BLOCKS.QUOTE]: quoteBlock,
  },
}

export default options
